<script setup>
import { useFooterSectionStore } from '@/stores/footer_sections';
import { useLanguagesStore } from '@/stores/languages';
import { useGeneralStore } from '@/stores/general';
import { version } from '../../../../package.json';
import _ from 'lodash';

const languagesStore = useLanguagesStore();
const footerSectionStore = useFooterSectionStore();
const generalStore = useGeneralStore();

const getBodyText = (item) => {
	// Get the locale of the selected language or default to 'en'
	const locale = languagesStore.selectedLanguage.locale ?? 'en';

	// Find the language object that matches the locale
	const selectedLanguageItem = _.find(item.languages, { locale: locale });

	// If found, return the body text; otherwise, return an empty string or handle fallback as needed
	return selectedLanguageItem ? selectedLanguageItem.pivot.body : '';
};
</script>

<template>
	<template v-if="footerSectionStore.getDescriptionSection && footerSectionStore.getDescriptionSection.items.length > 0">
		<h6 class="description-title">{{ $t('general.license') }}</h6>
		<template v-for="item in footerSectionStore.getDescriptionSection.items" :key="item.id">
			<p class="description-body" v-html="getBodyText(item)"></p>
		</template>
		<p class="description-body">© {{ generalStore.getGeneral.site_name }} 2024 Preview Build {{ version }} </p>
		<img style="margin-top: 20px; height: 37px !important;" :src="generalStore.getGeneral.footer_logo"  class="desktop-logo"/>
	</template>
</template>

<style scoped>
.description-body {
	font-size: 12px;
	color: #878a90;
}
.description-title {
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	padding-bottom: 5px;
}

@media screen and (max-width: 767px) {
	.desktop-logo {
		display: none;
	}
}
</style>
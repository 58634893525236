<script setup>
/* eslint-disable */
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { openModal } from '@kolirt/vue-modal';
import { BetSlip } from '@/stores/BetSlip';
import { useLanguagesStore } from '@/stores/languages';
import { useCurrenciesStore } from '@/stores/currencies';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useCustomerStore } from '@/stores/customer';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useOddsStore } from '@/stores/odds';
import { useSharedBetslipsStore } from '@/stores/shared_betslips';
import { useI18n } from 'vue-i18n';

import FreeBet from './FreeBet.vue';

import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';
import ShareBet from '@/components/modals/ShareBetModal.vue';

import commonSvgs from '@/assets/svgs/commonSvgs.json'
import { useInitializeStore } from '@/stores/initialize';

const svg_data = ref(commonSvgs);
const router = useRouter();

const oddsStore = useOddsStore();
const betslipStore = BetSlip();
const initializeStore = useInitializeStore();
const languagesStore = useLanguagesStore();
const currenciesStore = useCurrenciesStore();
const preSportsStore = usePreSportsStore();
const customerStore = useCustomerStore();
const liveSportsStore = useLiveSportsStore();
const sharedBetslipStore = useSharedBetslipsStore();
const { t } = useI18n();

const single_stakes = ref('');
const share_url = ref(null);
const loading = ref(false);
const copied = ref(false);
const share_id = ref(null);
const share_url_text = ref(t('general.copy_url'))
const share_id_text = ref(t('general.copy_code'))
const input_type = ref('');
const current_betSlip = ref(null);
const is_focused = ref(false);
const frominput = ref(false);

const showShareModal = () => {
	let data = [];
	loading.value = true;

	for (const index in betslipStore.betslip.bets) {
		const bet = betslipStore.betslip.bets[index];

		data.push({
			bet_uuid: bet.odd_info.uuid,
			fixture_id: bet.odd_info.fixture_id
		});
	};

	sharedBetslipStore.storeSharedBetslip(JSON.stringify(data)).then((response) => {
		loading.value = false;
		share_url.value = response.message;
		share_id.value = response.code;

		openModal(ShareBet , { share_url: share_url.value, share_id: share_id.value }).then((data) => {
			// Commenting out or removing the condition to show the register modal
			// if (data.show_register) showRegisterModal();
		}).catch(() => {});

	}).catch((error) => {
		loading.value = false;
	})

};

const removeFromBetslip = (bet) => {
	betslipStore.removeFromBetslip({ odd_uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id })
}

const acceptChanges = () => {
	betslipStore.removeBetslipErrors();
}

const removeAllBets = () => {
	betslipStore.resetBetslip();
	betslipStore.toggleBetslip();
}

const goToFixture = (bet) => {
	if (bet.odd_info.live) {
		router.push({ name: 'live-sports-eventview', params: { fixture_id: bet.odd_info.fixture_id } });
		return;
	}

	router.push({ name: 'pre-sports-fixturebook', params: { sport_id: bet.odd_info.sport_id, region_id: bet.odd_info.region_id, competition_id: bet.odd_info.competition_id, fixture_id: bet.odd_info.fixture_id } });
}

const focusStakeInput = (bet, type) => {
	is_focused.value = true;
	if (bet.stake == 0) bet.stake = '';
	input_type.value = type;
	current_betSlip.value = bet;
}

const setInputType = (type) =>{
	input_type.value = type;
	is_focused.value = true
}

const updateBetPrice = (type, value) => {
	// show hide dropdown
	isCheckedSingle.value = null;

	if (input_type.value == ''){
		alert("Please click on input");
	} else {
		if (input_type.value == 'all') {
			single_stakes.value = betslipStore.validateInput(single_stakes.value, type, value);
			// check for all bet slips
			for (const key in betslipStore.betslip.bets) {
				betslipStore.removeFreebetSingle(key);
			}
		} else if (input_type.value == 'single') {
			betslipStore.removeFreebetSingle(current_betSlip.value.odd_info.uuid);
			current_betSlip.value.stake = betslipStore.validateInput(current_betSlip.value.stake, type, value);
		} else {
			console.log("Some thing else came across");
		}
	}
}

const getMaxBet = () => {
	if (!customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	let bet_uuids = [];
	let payload = {};
	if (input_type.value == 'single') {
		bet_uuids.push({uuid: current_betSlip.value.odd_info.uuid, fixture_id: current_betSlip.value.odd_info.fixture_id});

		payload.type = 'singles';
	} else {
		for (const key in betslipStore.betslip.bets) {
			const bet = betslipStore.betslip.bets[key];
			bet_uuids.push({uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id});
		}

		payload.type = 'multiple';
	}

	payload.bets = bet_uuids;
	betslipStore.calculateMaxBet(payload).then(response => {
		if (input_type.value == 'single') current_betSlip.value.stake = response.max_bet.toFixed(2);
		else single_stakes.value = response.max_bet.toFixed(2);
	}).catch(error => {
		console.log(error);
	});

	console.log(payload);
}

const focusOutStakeInput = (bet) => {
	if (bet.stake == '') bet.stake = 0;
}

const calculateTax = (bet) => {
	if (isNaN(bet.stake)) return 0;

	let single_return = 0;
	if (bet.freebet_id) {
		single_return = (bet.stake * bet.odd_info.price) - bet.stake;
		return single_return * betslipStore.betslip_tax;
	} else {
		single_return = bet.stake * bet.odd_info.price;
		return (single_return - bet.stake) * betslipStore.betslip_tax;
	}
}

const calculateReturn = (bet) => {
	if (isNaN(bet.stake)) return 0;

	let tax_amount = 0;
	let single_return = 0;

	if (bet.freebet_id) {
		single_return = (bet.stake * bet.odd_info.price) - bet.stake;
		if (betslipStore.betslip_tax) tax_amount = single_return * betslipStore.betslip_tax;
	} else {
		single_return = bet.stake * bet.odd_info.price;
		if (betslipStore.betslip_tax) tax_amount = (single_return - bet.stake) * betslipStore.betslip_tax;
	}

	return single_return - tax_amount;
}

const caclulateTotalStakes = () => {
	let total_stakes = 0;
	for (const uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, uuid)) {
			const bet = betslipStore.betslip.bets[uuid];
			total_stakes += Number(bet.stake)
		}
	}

	return total_stakes;
}

const caclulateTotalReturn = () => {
	let total_return = 0;
	for (const uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, uuid)) {
			const bet = betslipStore.betslip.bets[uuid];
			total_return += calculateReturn(bet);
		}
	}

	return total_return;
}

const caclulateTotalTax = () => {
	let total_tax = 0
	for (const uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, uuid)) {
			const bet = betslipStore.betslip.bets[uuid];
			total_tax += calculateTax(bet);
		}
	}

	return total_tax;
}

const validateStake = (event, bet = false, betAll = false) => {
	//  check for freebet dropdown if open then close
	frominput.value = true;

	if (betAll === 'all') {
		for (const key in bet) {
			betslipStore.removeFreebetSingle(key);
		}
	}

	if (bet && !betAll) {
		betslipStore.removeFreebetSingle(bet.odd_info.uuid);
	}

	if (event.key === ',') {
		event.preventDefault();
		if (!event.target.value.includes('.')) event.target.value += '.';
	}

	if (
		(event.key < '0' || event.key > '9') &&
		event.key !== '.' &&
		event.key !== 'Backspace'
	) {
		event.preventDefault();
	}

}

const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() =>{});
}

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

const placeBetslip = () => {
	if (!customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	if (betslipStore.processing_placement) return;

	betslipStore.placeBetslip();
}

watch(single_stakes, (newValue, oldValue) => {
	for (const key in betslipStore.betslip.bets) {
		betslipStore.betslip.bets[key].stake = newValue
	}
});

const shareBetslip = () => {
	let data = [];
	loading.value = true;

	for (const index in betslipStore.betslip.bets) {
		const bet = betslipStore.betslip.bets[index];

		data.push({
			bet_uuid: bet.odd_info.uuid,
			fixture_id: bet.odd_info.fixture_id
		});
	};

	sharedBetslipStore.storeSharedBetslip(JSON.stringify(data)).then((response) => {
		loading.value = false;
		share_url.value = response.message;
		share_id.value = response.code;
	}).catch((error) => {
		loading.value = false;
	})
}

// const copyToClipboard = () => {
// 	navigator.clipboard.writeText(share_url.value);
// 	share_url_text.value = t('general.copied')

// 	setTimeout(() => {
// 		share_url_text.value = t('general.copy_url');
// 	}, 2000);
// }

// const copyIdToClipboard = () => {
// 	navigator.clipboard.writeText(share_id.value);
// 	share_id_text.value = t('general.copied')

// 	setTimeout(() => {
// 		share_id_text.value = t('general.copy_code');
// 	}, 2000);
// }

// Computed property to determine whether the checkbox is checked
const isCheckedSingle = ref(null);
const isDropdownCheck = ref(null);
const isTooltip = ref(false);

const selectItem = (value, uuid) => {
	const id = "#freebet_dropdown" + uuid;
	const defaultText = document.querySelector(id + ' .default-value p');
	if (defaultText) {
		defaultText.textContent = value;
		defaultText.classList.add('selected');
	}
	isDropdownCheck.value = null; // Close dropdown
	const elements = document.querySelectorAll('.dropdown-open');
	elements.forEach((element) => {
			element.classList.remove('dropdown-open');
	});
}
const closeDropdown = () => {
	isDropdownCheck.value = null;
}

document.addEventListener('click', (event) => {
	// Check if the clicked element is inside the dropdown
	const dropdown = document.querySelector('.freebet-select');
	const tooltip = document.querySelector('.tooltip-outer'); // Change '.tooltip' to your tooltip selector
	const targetElement = event.target;

	if (dropdown && !dropdown.contains(targetElement)) {
		// Clicked outside of the dropdown, close it
		closeDropdown();
	}

	// Check if tooltip exists and clicked outside of it
	if (tooltip && !tooltip.contains(targetElement)) {
		// Clicked outside of the tooltip, close it
		isTooltip.value = false;
	}
});

const getMaxBetDesktop = (bet) => {
	if (!customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	let bet_uuids = [];
	bet_uuids.push({uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id});

	let payload = {};
	payload.bets = bet_uuids
	payload.type = 'singles';

	betslipStore.calculateMaxBet(payload).then(response => {
		bet.stake = response.max_bet.toFixed(2);
	}).catch(error => {
		console.log(error);
	});
}
</script>

<template>
	<div class="betslip-top-area">
		<div class="betslip-beting-area">
			<!-- game -->
			<div class="inner-tab-content">
				<div class="bets_body" v-for="bet in betslipStore.betslip.bets" :key="bet.odd_info.uuid">
					<div class="over_row" :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
						<div class="left">
							<div class="top_content" :style="!bet.odd_info.live ? '' : ''">
								<div class="live-badge" v-if="bet.odd_info.live" v-html="svg_data.live_badge"></div>
								<div class="over">
									<p>{{ bet.odd_info.odd_name_translations[languagesStore.selectedLanguage.locale] }}</p>
								</div>
							</div>
							<div class="subtitle">
								<p>{{ bet.odd_info.market_name_translations[languagesStore.selectedLanguage.locale] }}</p>
							</div>
						</div>
						<div class="right ">
							<div class="value" :class="{'betup': bet.odd_info.price > bet.price, 'betdown': bet.odd_info.price < bet.price}">
								<p>{{ bet.odd_info.sp ? bet.odd_info.placeholder : oddsStore.oddsFormat(bet.odd_info.price) }}</p>
							</div>
							<span class="seprater"></span>
							<div class="clear-ico betslip-button" @click="removeFromBetslip(bet)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.741" viewBox="0 0 12 14.741">
									<g id="Group_1850" data-name="Group 1850" transform="translate(0.5 0.5)">
										<g id="delete" transform="translate(0)">
										<path id="Path_896" data-name="Path 896" d="M176,21.08A1.08,1.08,0,0,1,177.08,20h2.161a1.08,1.08,0,0,1,1.08,1.08" transform="translate(-172.507 -20)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
										<line id="Line_157" data-name="Line 157" x2="11" transform="translate(0 3.143)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
										<g id="Path_897" data-name="Path 897" transform="translate(-94.69 -135.265)" fill="none" stroke-linecap="round" stroke-miterlimit="10">
											<path d="M96,140l1.079,8.56a1.074,1.074,0,0,0,1.063.946h4.283a1.074,1.074,0,0,0,1.063-.946l1.079-8.56Z" stroke="none"/>
											<path d="M 97.13394165039062 141.0000152587891 L 98.07138824462891 148.4382934570312 C 98.07550048828125 148.4717102050781 98.10279846191406 148.5062866210938 98.14138793945312 148.5062866210938 L 102.4241638183594 148.5062866210938 C 102.4627304077148 148.5062866210938 102.4900283813477 148.4717102050781 102.4945220947266 148.4352569580078 L 103.4315795898438 141.0000152587891 L 97.13394165039062 141.0000152587891 M 96 140.0000152587891 L 104.5655212402344 140.0000152587891 L 103.4866714477539 148.560302734375 C 103.4211196899414 149.0935516357422 102.9704132080078 149.5062866210938 102.4241638183594 149.5062866210938 L 98.14138793945312 149.5062866210938 C 97.59510803222656 149.5062866210938 97.1444091796875 149.0935516357422 97.078857421875 148.560302734375 L 96 140.0000152587891 Z" stroke="none" fill="#dbe1e6"/>
										</g>
										<g id="Group_1823" data-name="Group 1823" transform="translate(4.804 7.854)">
											<line id="Line_158" data-name="Line 158" y1="3.165" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
											<line id="Line_159" data-name="Line 159" y1="3.165" transform="translate(1.571)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
										</g>
										</g>
									</g>
								</svg>
							</div>
						</div>
					</div>
					<div class="bottom_row">

						<div class="right">
							<div class="full_detail" :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
								<div class="icon">
									<img :src="preSportsStore.preSportWhiteIcon(bet.odd_info.sport_id)" alt="card-image">
								</div>
								<div class="value" @click="goToFixture(bet)">
									<div class="title_top">
										<p>{{ bet.odd_info.region_name_translations[languagesStore.selectedLanguage.locale] }} - {{ bet.odd_info.competition_name_translations[languagesStore.selectedLanguage.locale] }}</p>
									</div>

									<div v-if="liveSportsStore.hasOneParticipant(bet.odd_info.sport_id) || bet.odd_info.fixture_outright" class="title_bottom">
										<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }}</p>
									</div>

									<div v-else class="title_bottom">
										<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }} V {{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][1] }}</p>
									</div>
								</div>
							</div>
							<div class="value-right">
								<div class="stake-input" :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
									<span class="euro-icon">{{ currenciesStore.getCurrencySymbol }}</span>
									<input type="text" v-model="bet.stake" @keydown="validateStake($event, bet)" @focus="focusStakeInput(bet, 'single')" @focusout="focusOutStakeInput(bet)" inputmode="none" autocapitalize="off" autocomplete="off" autocorrect="off" min="0" class="pay_btn" :disabled="bet.odd_info == null || bet.odd_info.status == 'suspended'">
									<template v-if="initializeStore.screenWidth > 767">
										<a class="btn-small btn-secondary btn-max" @click="getMaxBetDesktop(bet)">{{ $t('betslip.max') }}</a>
									</template>
								</div>
							</div>

							<!-- Include The Component Here STart -->
							<template v-if="betslipStore.singleBetslipEligibleFreebets(bet.odd_info.uuid).length > 0">
								<FreeBet :uuid="bet.odd_info.uuid" :bet="bet" :frominput="frominput"/>
							</template>
							<!-- Include The Component Here End -->
						</div>
					</div>
				</div>
			</div>

			<div class="target-all">
					<div class="row">
					<div class="title">
						<p>{{$t('betslip.bet_all')}}</p>
					</div>

					<div class="right_content_85 stake-input">
						<span class="euro-icon">{{ currenciesStore.getCurrencySymbol }}</span>
						<input v-model="single_stakes" type="text" @keypress="validateStake($event, betslipStore.betslip.bets, 'all')" @focus="setInputType('all')" inputmode="none" autocapitalize="off" autocomplete="off" autocorrect="off" min="0" class="pay_btn">
						<template v-if="initializeStore.screenWidth > 767">
							<a class="btn-small btn-secondary btn-max" @click="getMaxBet()">{{ $t('betslip.max') }}</a>
						</template>
					</div>
				</div>
			</div>

			<!-- amount select start-->
			<div class="amount-select-main" :class={system_bet_numpad:is_focused}>
				<div class="amount-select-outer">
					<div class="amount-select-topbar">
						<div class="amount-selet-item">
							<p @click="updateBetPrice('add', 5)">+5</p>
						</div>
						<div class="amount-selet-item">
							<p @click="updateBetPrice('add', 10)">+10</p>
						</div>
						<div class="amount-selet-item">
							<p @click="updateBetPrice('add', 20)">+20</p>
						</div>
						<div class="amount-selet-item">
							<p @click="getMaxBet()">Max</p>
						</div>
					</div>
					<div class="amount-select-bottom" >
						<div class="amount-item" @click="updateBetPrice('append', 1)">
							<p>1</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 2)">
							<p>2</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 3)">
							<p>3</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 4)">
							<p>4</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 5)">
							<p>5</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 6)">
							<p>6</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 7)">
							<p>7</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 8)">
							<p>8</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 9)">
							<p>9</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', '.')">
							<p>.</p>
						</div>
						<div class="amount-item" @click="updateBetPrice('append', 0)">
							<p>0</p>
						</div>
						<div class="amount-item"  @click="updateBetPrice('delete', 0)">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
								<g id="Group_1851" data-name="Group 1851" transform="translate(-160 -502)">
									<path id="Path_898" data-name="Path 898" d="M0,0H15V14H0L-5,7Z" transform="translate(165 502)" fill="#202833"/>
									<path id="Path_899" data-name="Path 899" d="M.5-3.354,2.464-6.071H1.45L.007-4.03-1.45-6.071H-2.464l1.976,2.73L-2.906,0h.988L.007-2.652,1.9,0h1Z" transform="translate(171.905 512.071)" fill="#fff"/>
								</g>
							</svg>

						</div>
					</div>
				</div>
			</div>
			<!-- amount select end-->

		</div>
	</div>

	<div class="bets_body_bottom">

		<div class="row">
			<div class="title">
				<p> {{$t('betslip.total_stake')}}</p>
			</div>

			<div class="right_content_85 light">
				<p>{{ currenciesStore.format_currency(caclulateTotalStakes()) }}</p>
			</div>
		</div>

		<template v-if="!betslipStore.hasSPOdd">
			<div v-if="betslipStore.betslip_tax > 0" class="row total-bet">
				<div class="title">
					<p>{{$t('betslip.tax')}}</p>
				</div>

				<div class="right_content_85 text-white">
					<p>{{ currenciesStore.format_currency(caclulateTotalTax()) }}</p>
				</div>
			</div>

			<div class="row total-bet">
				<div class="title">
					<p>{{$t('betslip.potential_return')}}</p>
				</div>

				<div class="right_content_85 text-white">
					<p>{{ currenciesStore.format_currency(caclulateTotalReturn()) }}</p>
				</div>
			</div>
		</template>

		<div class="row_1">
			<template v-if="betslipStore.countBetslipBets == 50">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ $t('betslip.bet_limit_exceeded') }}</p>
					</div>
				</div>
			</template>
			<template v-if="betslipStore.betslip.error_message">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ betslipStore.betslip.error_message }}</p>
					</div>
				</div>
				<div class="bottom-multi-btn">
					<div class="share-grid">
						<!-- <div class="share-inner-grid">
							<button v-if="share_url" class="share-btn" type="button" @click="copyToClipboard()">
								<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_url_text }}
							</button>
							<button v-if="share_id" class="share-btn" type="button" @click="copyIdToClipboard()">
								<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_id_text }}
							</button>
						</div> -->
							<button class="share-btn btn-small" type="button" @click="showShareModal()" v-if="!loading">
								<span v-html="commonSvgs.share_ico"></span>&nbsp;{{ $t('general.share') }}
							</button>
							<div v-else class="loader-main"></div>
					</div>
					<a @click="acceptChanges" href="#" class="btn-small btn-secondary betslip-button">{{$t('betslip.accect_changes')}}</a>
				</div>

			</template>

			<template v-else>
				<div class="bottom-multi-btn">
					<div class="share-grid">
						<!-- <div class="share-inner-grid">
							<button v-if="share_url" class="share-btn" type="button" @click="copyToClipboard()">
								<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_url_text }}
							</button>
							<button v-if="share_id" class="share-btn" type="button" @click="copyIdToClipboard()">
								<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_id_text }}
							</button>
						</div> -->
							<button class="share-btn btn-small" type="button" @click="showShareModal()" v-if="!loading">
								<span v-html="commonSvgs.share_ico"></span>&nbsp;{{ $t('general.share') }}
							</button>
							<div v-else class="loader-main"></div>
					</div>
					<a @click="placeBetslip" href="#" class="btn-small btn-secondary betslip-button">
						{{ $t('betslip.place_bet') }} {{ currenciesStore.format_currency(caclulateTotalStakes()) }}
						<div v-if="betslipStore.processing_placement" class="lds-ring"><div></div><div></div><div></div><div></div></div>
					</a>
				</div>

			</template>
		</div>
	</div>

</template>
<style scoped>
.lds-ring {
	display: inline-block;
	position: relative;
	width: 15px;
	height: 15px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 2px;
	margin-right: 10px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.eventime_mobal_content .bets_body_bottom .row .right_content_85 input {
	width: 75px;
}


.disabled-button {
	background: transparent;
	pointer-events: none;
	border: 1px solid #4E545D;
	color: #4E545D;
}
.loader-main {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: relative;
}

.register-input-check {
	padding: 4px 0px 0;
	display: flex;
	align-items: flex-start;
	gap: 8px;
}

.register-input-check input[type=checkbox] {
	-webkit-appearance: checkbox !important;
	-moz-appearance: checkbox !important;
	appearance: checkbox !important;
	margin-top: 4px;
}

.check-dash {
	position: absolute;
	opacity: 0;
}

.check-dash + label {
	position: relative;
	padding: 0;
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	gap: 3%;
	width: 100%;
}

.register-input-check p {
	color: #fff;
	font-size: 12px;
	text-align: start;
	cursor: pointer;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.register-input-check .check-dash + label:before {
	content: "";
	margin-right: 0px;
	display: inline-block;
	vertical-align: text-top;
	width: 16px;
	height: 16px;
	background: transparent;
	outline: 1px solid #878A90;
	border-radius: 3px;
	margin-top: 1px;
	cursor: pointer;
}
.register-input-check .check-dash:checked + label:after {
	content: "";
	position: absolute;
	left: 6px;
	top: 4px;
	display: inline-block;
	transform: rotate(45deg);
	height: 9px;
	width: 4px;
	border-bottom: 1px solid #63A246;
	border-right: 1px solid #63A246;
}

html[dir="rtl"] .register-input-check .check-dash:checked + label:after {
	right: 6px;
	left: unset;
}

.btn-max {
	padding: 4px !important;
}

.eventime_mobal_content .target-all .row .stake-input input {
	width: 90px !important;
}
@media screen and (min-width: 768px) {

	.eventime_mobal_content .target-all .row .stake-input {
		max-width: 150px !important;
		width: 140px!important;
	}
}
</style>
<!-- eslint-disable -->
<script setup>
import { computed, onMounted, ref, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useCustomerStore } from '@/stores/customer';
import { useDatesStore } from '@/stores/dates';
import { useCurrenciesStore } from '@/stores/currencies';
import { useGeneralStore } from '@/stores/general';
import { openModal } from '@kolirt/vue-modal';
import { useI18n } from 'vue-i18n';
import TabNav from '../Common/tabNav.vue';
import SettingDropdown from '../Common/settingDropdown.vue';
import LanguageChangeMobile from '../Common/LanguageChange.vue';
import OddSelectorMobile from '../Common/OddSelector.vue';
import SettingOddsMobile from '../Common/SettingOdds.vue';
import UserDropdown from '../Common/userDropdown.vue';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import Login from '../modals/Login.vue';
import Register from '../modals/Register.vue';
import SportsbookSearch from '../modals/SportsbookSearch.vue';
import { useNavbarStore } from '@/stores/navbar_items';
import { BetSlip } from '@/stores/BetSlip'
import { useMenuStore } from '@/stores/menus';
import { useRacingSportsStore } from '@/stores/racing_sports';
import NotificationsDropdown from '@/components/Common/notificationsDropdown.vue';
import { useSharedBetslipsStore } from '@/stores/shared_betslips';
import SharedBetslipError from '@/components/modals/SharedBetslipError.vue';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useMobileDashboardPrevious } from "@/stores/mobile_dashboard_previous_route"
import AccountMobileDashboard from '@/components/account/account_mobile/AccountMobile.vue'

const router = useRouter();
const { t } = useI18n();
const menuStore = useMenuStore();
const customerStore = useCustomerStore();
const datesStore = useDatesStore();
const currenciesStore = useCurrenciesStore();
const generalStore = useGeneralStore();
const navbarStore = useNavbarStore();
const betslipStore = BetSlip();
const racingSportsStore = useRacingSportsStore();
const sharedBetslipStore = useSharedBetslipsStore();
const preSportsStore = usePreSportsStore();
const mobileDashboardStore = useMobileDashboardPrevious();

const svgs_data = commonSvgs;
const scrolled = ref(false);
const screenWidth = ref(window.innerWidth);
const shared_betslip_code = ref(null);
const button_disabled = ref(true);

const tooltipText = computed(() => {
	if (customerStore.verificationDaysLeft.type == 'email') {
		if (customerStore.verificationDaysLeft.days == 1) return t('account.verification_process.day_left_to_verify_email') + ' ' + t('account.verification_process.your_account_will_expire_at') + ' ' + datesStore.defaultDate(customerStore.verificationDaysLeft.datetime);
			return t('account.verification_process.days_left_to_verify_email', { days: customerStore.verificationDaysLeft.days }) + ' '  + t('account.verification_process.your_account_will_expire_at') + datesStore.defaultDate(customerStore.verificationDaysLeft.datetime);
	} else if (customerStore.verificationDaysLeft.type == 'documents') {
		if (customerStore.verificationDaysLeft.days == 1) return t('account.verification_process.day_left_to_verify_documents') + ' ' + t('account.verification_process.your_account_will_expire_at') + ' ' + datesStore.defaultDate(customerStore.verificationDaysLeft.datetime);
			return t('account.verification_process.days_left_to_verify_documents' , { days: customerStore.verificationDaysLeft.days }) + ' ' + t('account.verification_process.your_account_will_expire_at')  + datesStore.defaultDate(customerStore.verificationDaysLeft.datetime);
	}
})

const checkScroll = () => {
	if (screenWidth.value <= 991) return
	if (window.scrollY > 100) scrolled.value = true;
	else scrolled.value = false;
}

const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() =>{});
};

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};


const activeTab = ref(null);

const setActiveTab = (tab) => {
	if (activeTab.value === tab) {
		activeTab.value = null; // Close the tab if it's already active
	} else {
		activeTab.value = tab; // Open the tab
	}
};

const closeTab = (tab) => {
	if (activeTab.value === tab) {
		activeTab.value = null; // Close the tab on double-click
	}
};


// Define reactive variables
const isDashboardActive = ref(false);

const setDashboardActive = () => {
	isDashboardActive.value = true;
};

watch(isDashboardActive, (isActive) => {
	const updateClass = () => {
		if (window.innerWidth < 768 && isActive) {
			document.body.classList.add('dashboardMobile-active');
		} else {
			document.body.classList.remove('dashboardMobile-active');
		}
	};

	// Initial check when the watcher is triggered
	updateClass();

	// Add resize event listener
	window.addEventListener('resize', () => {
		if (window.innerWidth >= 768) {
			document.body.classList.remove('dashboardMobile-active');
		} else {
			if (isActive) {
				document.body.classList.add('dashboardMobile-active');
			} else {
				document.body.classList.remove('dashboardMobile-active'); // Ensure it's removed if isActive is false
			}
		}
	});

	// Clean up the event listener on watcher destroy
	return () => {
		window.removeEventListener('resize', updateClass);
	};
});

const closeDashboard = (event) => {
  event.stopPropagation();  // Prevents triggering the setDashboardActive when closing
  isDashboardActive.value = false;
};

const showSportsbookSearchModal = (event) => {
	event.stopPropagation();
	openModal(SportsbookSearch).catch(() => {});
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
	navbarStore.user_dropdown_opened = false;
	navbarStore.settings_dropdown = false;
	navbarStore.mobile_menu = false;
	navbarStore.notifications_dropdown = false;

};

const showSettings = () => {
	navbarStore.settings_dropdown = !navbarStore.settings_dropdown;
	navbarStore.user_dropdown_opened = false;
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
	navbarStore.mobile_menu = false;
	navbarStore.notifications_dropdown = false;
};

const showUserDropdown = () => {
	navbarStore.user_dropdown_opened = !navbarStore.user_dropdown_opened;
	navbarStore.settings_dropdown = false;
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
	navbarStore.mobile_menu = false;
	navbarStore.notifications_dropdown = false;
};

const showNotificationsDropdown = () => {
	navbarStore.notifications_dropdown = !navbarStore.notifications_dropdown;
	navbarStore.user_dropdown_opened = false;
	navbarStore.settings_dropdown = false;
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
	navbarStore.mobile_menu = false;
}

const showNotificationsDropdownMobile = () => {
	navbarStore.notifications_dropdownMobile = !navbarStore.notifications_dropdownMobile;
	navbarStore.user_dropdown_opened = false;
	navbarStore.settings_dropdown = false;
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
	navbarStore.mobile_menu = false;
}

const toggleMobileMenu = () => {
	navbarStore.mobile_menu = !navbarStore.mobile_menu;
	navbarStore.closeAll()
	betslipStore.is_betslip_opened = false;
	betslipStore.betSlipModel = '';
}

const toggleBalanceVisibility = () => {
	if (customerStore.balance_visibility == true) customerStore.balance_visibility = false;
	else customerStore.balance_visibility = true;
};

const getUrl = (menu_child) => {
	if (!menu_child.click_url && !menu_child.static_page_url) return false
	if (menu_child.click_url == '#/promotions/all') return menu_child.click_url.replace('#', '').replace('/all', '');
	if (menu_child.click_url && menu_child.click_url.startsWith('#')) return menu_child.click_url.replace('#', '');
	else if (menu_child.click_url && menu_child.click_url.startsWith('http')) return menu_child.click_url
	else if (menu_child.click_url && menu_child.click_url.startsWith('/')) return menu_child.click_url
	else if (menu_child.static_page_url) return menu_child.static_page_url;
}


const getUrlHeader = (data) => {
	if (data.click_url == '#/pre-sports' || data.click_url == '/pre-sports') {
		return { name: 'pre-sports-highlights', params: { sport_id: '' } };
	} else if (data.click_url == '#/live-sports' || data.click_url == '/live-sports') {
		return { name: 'live-sports-overview', params: { sport_id: '' } };
	} else if (data.click_url == null && data.static_page_url) {
		return data.static_page_url
	} else if (data.click_url &&  data.click_url.startsWith('http')) {
		return data.click_url;
	} else if (data.click_url && data.click_url.startsWith('#/')) {
		return data.click_url.replace('#/', '/');
	} else if (data.click_url && data.click_url.startsWith('/')) {
		return data.click_url;
	} else if (data.click_url) {
		return data.click_url;
	}
}

const updateScreenWidth = () => {
	screenWidth.value = window.innerWidth;
}

const goToRacingSports = () => {
	// must get first racing sport from getter and navigate there
	let first_sport = racingSportsStore.racingSports[0];

	router.push({name: 'racing-coupons', params: {sport_id: first_sport.id}});
	racingSportsStore.setSelectedRacingSportId(first_sport.id);
	toggleMobileMenu()
}

const goToESports = () => {
	let first_sport = preSportsStore.esports[0];

	router.push({name: 'esports-highlights', params: {sport_id: first_sport.id}});
	preSportsStore.setSelectedEsportId(first_sport.id);
	toggleMobileMenu()
}

const goToPreSports = () => {
	let second_sport = preSportsStore.preSports[0];

	router.push({name: 'pre-sports-highlights', params: {sport_id: second_sport.id}});
	preSportsStore.setSelectedEsportId(second_sport.id);
	toggleMobileMenu()
}

const clickOutsideUserDropdown = (event) => {
	const isSVGElement = event.target instanceof SVGElement || event.target.parentNode instanceof SVGElement;

	const userDropdown = document.getElementById('navbarStore.user_dropdown_opened');

	if (userDropdown && !userDropdown.contains(event.target) && !isSVGElement) {
		navbarStore.user_dropdown_opened = false;
	}
};

const clickOutsideSettings = (event) => {
	const settings = document.getElementById('settings-dropdown');

	if (settings && !settings.contains(event.target)) {
		navbarStore.settings_dropdown = false;
	}
};
const clickOutsideMenu = (event) => {
	const mobileMenu = document.getElementById('mobile-menu');
	if (mobileMenu && !mobileMenu.contains(event.target)) {
		navbarStore.mobile_menu = false;
	}
};

const clickOutsideNotifications = (event) => {
	const notifications = document.getElementById('notifications-dropdown');

	if (notifications && !notifications.contains(event.target)) {
		navbarStore.notifications_dropdown = false;
	}
};

watch(() => shared_betslip_code.value, (newValue, oldValue) => {
	if (newValue != '' && newValue != null) button_disabled.value = false;
	else if (newValue == '' || newValue == null) button_disabled.value = true;
})

const fetchSharedBetslip = () => {
	if (shared_betslip_code.value == null && shared_betslip_code.value == '') return

	navbarStore.mobile_menu = false;

	sharedBetslipStore.fetchSharedBetslip(shared_betslip_code.value).then((response) => {
		betslipStore.resetBetslip();

		let bets = JSON.parse(response)
		let shared_id = shared_betslip_code.value

		let must_delete = false;

		for (let index in bets) {
			let bet = bets[index];

			betslipStore.toggleToBetslip({ odd_uuid: bet.bet_uuid, fixture_id: bet.fixture_id }, shared_id).catch(error => {
				if (!must_delete) {
					sharedBetslipStore.deleteSharedBetslip(shared_id, false);
					must_delete = true;
				}
			});
		}

		shared_betslip_code.value = null;

	}).catch((error) => {
		openModal(SharedBetslipError, {errors: error.response, need_redirect: false}).catch(() =>{});
	})
}

// Add a watcher to add/remove a class on the body element based on navbarStore.mobile_menu
watch(() => navbarStore.mobile_menu, (newValue) => {
	if (newValue) {
		document.body.classList.add('mobile-menu-active');
	} else {
		document.body.classList.remove('mobile-menu-active');
	}
});

onMounted(() => {
	window.addEventListener("resize", () => {
		updateScreenWidth();
	});
	window.addEventListener('scroll', checkScroll);
	document.addEventListener('click', clickOutsideUserDropdown);
	document.addEventListener('click', clickOutsideSettings);
	document.addEventListener('click', clickOutsideNotifications);
	document.addEventListener('click', clickOutsideMenu);

	const previous_route_data = mobileDashboardStore.getMobileDashboardPrevious
	if(previous_route_data?.name){
		isDashboardActive.value = true;
		mobileDashboardStore.setMobileDashboardPrevious(null)
	} else{
		document.body.classList.remove('dashboardMobile-active');
	}
})

onUnmounted(() => {
	window.removeEventListener("resize", null);
	document.removeEventListener('click', clickOutsideUserDropdown);
	document.removeEventListener('click', clickOutsideSettings);
	document.removeEventListener('click', clickOutsideNotifications);
	document.addEventListener('click', clickOutsideMenu);
});
</script>
<template>
	<header class="header_without_login header-home" :class="{ 'Logged-in': customerStore.isLoggedIn, 'dark-header': scrolled }">
		<div class="container-lg header-inner">
			<div class="header-left">
				<div class="logo logo-desktop">
					<router-link to="/">
						<!-- <a href="#"><img class="logo-img" style="max-width: 130px;" :src="menuStore.isMobile != true ? generalStore.getGeneral.header_logo : generalStore.getGeneral.header_logo_mobile" alt=""></a> -->
						<img class="logo-img" style="max-width: 130px;" :src="generalStore.getGeneral.header_logo" alt="logo-desktop">
					</router-link>
				</div>
				<div class="logo logo-mobile">
					<router-link to="/">
						<!-- <a href="#"><img class="logo-img" style="max-width: 130px;" :src="menuStore.isMobile != true ? generalStore.getGeneral.header_logo : generalStore.getGeneral.header_logo_mobile" alt=""></a> -->
						<img class="logo-img" :src="generalStore.getGeneral.header_logo_mobile" alt="logo-desktop">
					</router-link>
				</div>
				<nav class="menu-desktop" v-if="menuStore.getHeaderMenus">
					<ul class="nav-list">
						<li v-for="(data, index) in menuStore.getHeaderMenus" :key="index">
							<template v-if="data.children && data.children.length > 0">
								<div class="dropdown">
									{{ data.name }}
									<ul class="dropdown-menu">
										<li v-for="(child, i) in data.children" :key="i" :class="$route.path == getUrl(child) ? 'active' : ''">
											<a :href="getUrl(child)">{{ child.name }}</a>
										</li>
									</ul>
								</div>
							</template>
							<template v-else>
								<li v-if="data.click_url && data.click_url.startsWith('http')">
									<a :href="getUrlHeader(data)">{{ data.name }}</a>
								</li>
								<li v-else-if="data.click_url && data.click_url == '/esports' && preSportsStore.esports.length > 0">
									<a @click="goToESports" style="cursor: pointer">{{ $t('general.esports') }}</a>
								</li>
								<li v-else-if="data.click_url && data.click_url == '/racing' && racingSportsStore.racingSports.length > 0" >
									<a style="cursor:pointer;" @click="goToRacingSports">{{ $t('general.racing') }}</a>
								</li>
								<li v-else>
									<router-link :to="getUrlHeader(data)">{{ data.name }}</router-link>
								</li>
							</template>
						</li>
					</ul>
				</nav>
				<nav class="menu-mobile" id="mobile-menu">
					<div class="nav-mobile"  @click="toggleMobileMenu()">
						<a id="nav-toggle" href="#!" :class="navbarStore.mobile_menu == true ? 'active' : ''"><span></span></a>
					</div>
					<Transition>
						<div class="nav-list" v-if="navbarStore.mobile_menu">
							<template v-if="menuStore.getHeaderMenus || menuStore.getStaticPageMenu">
								<!-- <ul class="nav-list-inner" :style="{ 'margin-bottom': customerStore.isLoggedIn ? '20px' : '0' }">
									<div class="first-menu">
										<li v-for="(data, index) in menuStore.getHeaderMenus" :key="index">
											<div v-if="data.children && data.children.length > 0">
												<h6>{{data.name}}</h6>
												<li v-for="(child, i) in data.children" :key="i">
													<a :href="getUrl(child)">{{child.name}}</a>
												</li>
											</div>
											<div v-else>
												<li v-if="data.click_url && data.click_url.startsWith('http')">
													<a :href="getUrlHeader(data)" class="basic-menu-font">{{ data.name }}</a>
												</li>
												<li v-else-if="data.click_url && data.click_url == '/esports' && preSportsStore.esports.length > 0">
													<a @click="goToESports" style="cursor: pointer">{{ $t('general.esports') }}</a>
												</li>
												<li v-else-if="data.click_url && data.click_url == '/racing' && racingSportsStore.racingSports.length > 0">
													<a style="cursor:pointer;" @click="goToRacingSports">{{ $t('general.racing') }}</a>
												</li>
												<li :class="data.media_url ? 'icon': ''" v-else>
													<img v-if="data.media_url" :src="data.media_url" class="header-mobile-icon">
													<router-link v-if="data.click_url != '/live-chat'" :to="getUrlHeader(data)" class="basic-menu-font">{{ data.name }}</router-link>
												</li>
											</div>
										</li>
									</div>
									<div class="second-menu">
										<template v-for="(data, index) in menuStore.getHeaderMenus" :key="index">
											<li v-if="data.click_url == '/live-chat'">
												<a href="javascript:void(0);" onclick="zE.activate({hideOnClose: true});" class="custom-font">{{ data.name }}<i class="fas fa-comments"></i></a>
											</li>
										</template >
										<li>
											<a href="#" class="custom-font">{{ $t('general.download_the_app') }}<i class="fas fa-download"></i></a>
										</li>
									</div>
									<div class="third-menu">
										<div v-for="(data, index) in menuStore.getStaticPageMenu" :key="index">
											<div v-if="data.children && data.children.length > 0">
												<h6>{{data.name}}</h6>
												<li v-for="(child, i) in data.children" :key="i">
												<a :href="getUrl(child)">{{child.name}}</a>
												</li>
											</div>
											<div v-else>
												<li>
												<a :href="getUrl(data)"> {{ data.name }} </a>
												</li>
											</div>
										</div>
									</div>
									<div class="bottom-menu">
										<div class="text-field">
											<input type="text" class="form-input" v-model="shared_betslip_code" :placeholder="$t('general.type_shared_bet_code')">
											<a @click="shared_betslip_code = null"><i class="fa fa-times-circle clear-button" aria-hidden="true"></i></a>
										</div>
										<button class="share-btn" :class="{'btn-disabled': button_disabled}" type="button" @click="fetchSharedBetslip()">
											<i class="fa fa-plus" aria-hidden="true" style="margin-right: 2px;"></i>&nbsp;{{ $t('general.add_bets') }}
										</button>
									</div>


									<div class="third-menu" v-for="(data, index) in menuStore.getStaticPageMenu" :key="index">

										<h6>{{data.name}}</h6>
										<li v-for="(child, i) in data.children" :key="i">
											<a :href="getUrl(child)">{{child.name}}</a>
										</li>
									</div>

								</ul> -->

								<div class="mobile-menu-top">
									<!-- menu top tabing start -->
									<div class="mobile-menu-tab">
										<div class="tab">
											<button class="tablinks" :class="activeTab=='language'?'active':''" @click="setActiveTab('language')" @dblclick="closeTab('language')"><img src="@/assets/images/lang-ico.svg" alt="Lang Icon">Language</button>
											<button class="tablinks" :class="activeTab=='odds'?'active':''" @click="setActiveTab('odds')" @dblclick="closeTab('odds')"><img src="@/assets/images/odd-ico.svg" alt="odd Icon">Odds Format</button>
											<button v-if="customerStore.isLoggedIn" class="tablinks" :class="activeTab=='setting'?'active':''" @click="setActiveTab('setting')" @dblclick="closeTab('setting')"><img src="@/assets/images/setting-ico.svg" alt="setting Icon">Settings</button>
										</div>
										<div class="tabing-content">
											<!-- language tab content start -->
											<div id="language" class="tab-content" :class="activeTab=='language'?'active':''">
												<div class="menu-back-option">
													<div class="menu-back-inner" @click="closeTab('language')">
														<object v-html="commonSvgs.arrow_left"></object>
														<p>{{ $t('general.menu') }}</p>
													</div>
												</div>
												<LanguageChangeMobile></LanguageChangeMobile>
											</div>
											<!-- language tab content end -->

											<!-- odds tab content start -->
											<div id="odds" class="tab-content" :class="activeTab=='odds'?'active':''">
												<div class="menu-back-option">
													<div class="menu-back-inner" @click="closeTab('odds')">
														<object v-html="commonSvgs.arrow_left"></object>
														<p>{{ $t('general.menu') }}</p>
													</div>
												</div>
												<OddSelectorMobile></OddSelectorMobile>
											</div>
											<!-- odds tab content end -->

											<!-- setting tab content start -->
											<div id="setting" class="tab-content" :class="activeTab=='setting'?'active':''">
												<div class="menu-back-option">
													<div class="menu-back-inner" @click="closeTab('setting')">
														<object v-html="commonSvgs.arrow_left"></object>
														<p>{{ $t('general.menu') }}</p>
													</div>
												</div>
												<SettingOddsMobile></SettingOddsMobile>
											</div>
											<!-- setting tab content end -->
										</div>
									</div>

									<!-- menu top tabing end -->

									<!-- menu scroll area start -->
									<div class="mobile-top-area">
										<div class="mobile-main-menu">
											<div class="mobile-menu-items" >
												<div class="mobile-menu-item-inner" v-for="(data, index) in menuStore.getHeaderMenus" :key="index">
													<template v-if="data.children && data.children.length > 0">
														<div class="mobile-item-inner" v-for="(child, i) in data.children" :key="i" @click="getUrl(child)">
															<div class="menu-icons-outer ss">
															<object class="white-ico" v-html="commonSvgs.sports_ico"></object>
															<object class="green-ico" v-html="commonSvgs.Green_sports_ico"></object>
															</div>
															<p>{{ child.name }}</p>
														</div>
													</template>
													<template v-else>
														<div class="mobile-item-inner" v-if="data.click_url && data.click_url.startsWith('http')" @click="toggleMobileMenu">
															<div class="menu-icons-outer">
															<object class="white-ico" v-html="commonSvgs.sports_ico"></object>
															<object class="green-ico" v-html="commonSvgs.Green_sports_ico"></object>
															</div>
															<p>{{ data.name }}</p>
														</div>
														<div class="mobile-item-inner" v-else-if="data.click_url === '/esports' && preSportsStore.esports.length > 0" @click="goToESports">
															<div class="menu-icons-outer">
															<object class="white-ico" v-html="commonSvgs.sports_ico"></object>
															<object class="green-ico" v-html="commonSvgs.Green_sports_ico"></object>
															</div>
															<p>{{ $t('general.esports') }}</p>
														</div>
														<div class="mobile-item-inner" v-else-if="data.click_url === '/racing' && racingSportsStore.racingSports.length > 0" @click="goToRacingSports">
															<div class="menu-icons-outer">
																<object class="white-ico" v-html="commonSvgs.horse_r_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_horse_r_ico"></object>
															</div>
															<p>{{ $t('general.racing') }}</p>
														</div>
														<div class="mobile-item-inner" v-else>
															<div class="menu-icons-outer" :class="{ 'icon': data.media_url }">
															<template v-if="data.click_url == '/pre-sports'">
																<object class="white-ico" v-html="commonSvgs.sports_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_sports_ico"></object>
															</template>
															<template v-if="data.click_url == '/live-sports'">
																<object class="white-ico" v-html="commonSvgs.live_badge_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_live_badge_ico"></object>
															</template>
															<template v-if="data.click_url == '/live-casino'">
																<object class="white-ico" v-html="commonSvgs.casino_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_casino_ico"></object>
															</template>
															<template v-if="data.click_url == '/casino'">
																<object class="white-ico" v-html="commonSvgs.dice_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_dice_ico"></object>
															</template>
															<template v-if="data.click_url == '/promotions'">
																<object class="white-ico" v-html="commonSvgs.gift_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_gift_ico"></object>
															</template>
															<template v-if="data.click_url == '/aviator'">
																<object class="white-ico" v-html="commonSvgs.aviator_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_aviator_ico"></object>
															</template>
															<template v-if="data.click_url == '/racing'">
																<object class="white-ico" v-html="commonSvgs.horse_r_ico"></object>
																<object class="green-ico" v-html="commonSvgs.Green_horse_r_ico"></object>
															</template>
															</div>
															<router-link v-if="data.click_url !== '/live-chat'" :to="getUrlHeader(data)" class="basic-menu-font" @click="toggleMobileMenu">
																<p>{{ data.name }}</p>
															</router-link>
														</div>
													</template>
												</div>

											</div>
										</div>

										<div class="useful-links-main">
											<h3>{{ $t('general.useful_links') }}</h3>
											<div class="useful-links-inner" >
												<div class="useful-item-main" v-for="(data, index) in menuStore.getStaticPageMenu" :key="index">
													<div v-if="data.children && data.children.length > 0" class="useful-links-item">
														<p><a :href="getUrl(link)">{{link.name}}</a></p>
													</div>
													<div v-else class="useful-links-item">
														<p><a :href="getUrl(data)"> {{ data.name }} </a></p>
													</div>
												</div>
											</div>

										</div>
									</div>
									<!-- menu scroll area end -->

									<!-- share predicton start -->
									<div class="share-prediction-bottom">
										<div class="share-prediction-inner">
											<h3>{{ $t('general.Share_your_predictions') }}</h3>
											<form @submit.prevent>
												<div class="fieldset">
													<input type="text" name="fullname"  class="form-text" v-model="shared_betslip_code" :placeholder="$t('general.type_shared_bet_code')">
													<span @click="shared_betslip_code = null" class="clear-text"><object v-html="commonSvgs.close"></object></span>
												</div>
												<button :class="{'btn-disabled': button_disabled}" class="btn-small btn-secondary" @click="fetchSharedBetslip()">{{ $t('general.add_bets') }}</button>
											</form>
										</div>
									</div>
									<!-- share predicton end -->
								</div>
							</template>
						</div>
					</Transition>
				</nav>

			</div>

			<div class="login-info" v-if="customerStore.isLoggedIn">
				<div class="email-verification-icon" v-if="customerStore.verificationDaysLeft">
					<div class="email-verification-inner">
						<span>{{ customerStore.verificationDaysLeft.days }}</span>
						<object v-html="svgs_data.calendar"></object>
					</div>
					<div class="tooltip-main bottom">
						<div class="tooltip-inner">
							<p>{{ tooltipText }}</p>
						</div>
					</div>
				</div>
				<div class="deposite-btn">
					<router-link to="/account/bank/deposits" class="btn-small btn-secondary">{{$t('general.deposit')}}</router-link>
				</div>

				<div class="notification-mobile" @click="showNotificationsDropdownMobile()">
					<a class="notifications-main" id="notifications-dropdownMobile">
						<div class="notification-icon-area">
							<object v-html="commonSvgs.notification_popup"></object>
							<span v-if="customerStore.getCustomer.unread_notifications_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_notifications_count }}</span>
						</div>
						<div v-if="navbarStore.notifications_dropdownMobile" class="notifications-dropdown-main">
							<NotificationsDropdown v-if="navbarStore.notifications_dropdownMobile"></NotificationsDropdown>
						</div>
					</a>
				</div>

				<div class="user-profile-top" :class="navbarStore.user_dropdown_opened ? 'active' : ''" id="navbarStore.user_dropdown_opened">
					<div class="mobile-user-ico" @click="setDashboardActive" :class="{ active: isDashboardActive }">
						<!-- <router-link to="/account/account-mobile/account-mobile-dashboard"><img src="@/assets/images/user-ico.svg" alt="user icon"></router-link> -->
						<img src="@/assets/images/user-ico.svg" alt="user icon">
					</div>
					<div class="accountDashboardMobile" v-if="isDashboardActive">
						<div class="dashboard-mobile-top">
							<div></div>
							<div class="dashboard-title">
								<h6><img src="@/assets/images/user-ico.svg" alt="user icon">{{ customerStore.getCustomer.username }}</h6>
							</div>
							<div class="close-btn" @click="closeDashboard">
								<object v-html="commonSvgs.close"></object>
							</div>
						</div>
						<AccountMobileDashboard ></AccountMobileDashboard>
					</div>

					<div class="user-detail" @click="showUserDropdown()">
						<p>{{ customerStore.getCustomer.username }}  </p>
						<svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
							<g id="Group_165" data-name="Group 165" transform="translate(1676.207 587.207) rotate(180)">
								<line id="Line_5" data-name="Line 5" x2="6.034" y2="6" transform="translate(1663.5 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
								<line id="Line_6" data-name="Line 6" x1="6.034" y2="6" transform="translate(1669.466 580.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
							</g>
						</svg>
					</div>

					<UserDropdown></UserDropdown>
				</div>

				<div class="profile-balance" @click="setDashboardActive">
					<p>{{$t('general.balance')}}</p>
					<h6 v-if="customerStore.balance_visibility == true">{{ currenciesStore.format_currency(customerStore.getCustomer.balance.combined_balance, customerStore.getCustomer.currency.code) }}</h6>
					<h6 v-else>*********</h6>
				</div>
				<div class="icon desktop-only">
					<img v-if="customerStore.balance_visibility == true" @click="toggleBalanceVisibility()" src="/assets/images/eye.svg" alt style="cursor: pointer" />
					<img v-else @click="toggleBalanceVisibility()" src="/assets/images/Icon_feather-eye-off.svg" alt style="cursor: pointer" />
				</div>


				<div class="header-icon">
					<div @click="showNotificationsDropdown()" class="notifications-main" id="notifications-dropdown">
						<span v-if="customerStore.getCustomer.unread_notifications_count > 0" class="notification-num">{{ customerStore.getCustomer.unread_notifications_count }}</span>
						<object v-html="commonSvgs.notification_popup"></object>

						<div v-if="navbarStore.notifications_dropdown" class="notifications-dropdown-main">
							<NotificationsDropdown v-if="navbarStore.notifications_dropdown"></NotificationsDropdown>
						</div>
					</div>

					<div class="setting-main" id="settings-dropdown">
						<object  v-html="svgs_data.setting_icon" @click="showSettings()"></object>
						<div v-if="navbarStore.settings_dropdown" class="setting-dropdown-main active">
							<setting-dropdown v-if="navbarStore.settings_dropdown"></setting-dropdown>
						</div>
					</div>

					<a href="#" class="login-search" @click="showSportsbookSearchModal($event)">
						<svg id="Icon_search" data-name="Icon search" xmlns="http://www.w3.org/2000/svg" width="18.707" height="18.707" viewBox="0 0 18.707 18.707">
							<g id="Path_3" data-name="Path 3" transform="translate(-2.25 -2.25)" fill="none" stroke-linecap="round" stroke-linejoin="round">
								<path d="M18.75,10.5A8.25,8.25,0,1,1,10.5,2.25a8.25,8.25,0,0,1,8.25,8.25Z" stroke="none" />
								<path d="M 10.50000190734863 17.75 C 14.49766159057617 17.75 17.75000190734863 14.49765968322754 17.75000190734863 10.5 C 17.75000190734863 6.502339839935303 14.49766159057617 3.25 10.50000190734863 3.25 C 6.502341747283936 3.25 3.250001907348633 6.502339839935303 3.250001907348633 10.5 C 3.250001907348633 14.49765968322754 6.502341747283936 17.75 10.50000190734863 17.75 M 10.50000190734863 18.75 C 5.943651676177979 18.75 2.250001907348633 15.0563497543335 2.250001907348633 10.5 C 2.250001907348633 5.943649768829346 5.943651676177979 2.25 10.50000190734863 2.25 C 15.05635166168213 2.25 18.75000190734863 5.943649768829346 18.75000190734863 10.5 C 18.75000190734863 15.0563497543335 15.05635166168213 18.75 10.50000190734863 18.75 Z" stroke="none" fill="#888a8f" />
							</g>
							<path id="Path_4" data-name="Path 4" d="M15.75,15.75l-3.262-3.262" transform="translate(2.25 2.25)" fill="none" stroke="#888a8f" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
						</svg>
					</a>
				</div>
			</div>

			<template v-else>
				<div class="right-content rightNav-desktop" v-if="screenWidth > 767">
					<div class="header-icons">
						<a href="#" @click="showSportsbookSearchModal($event)">
							<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 25.207 25.207">
								<g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-1.75 -1.75)">
									<path id="Path_3" data-name="Path 3" d="M24.75,13.5A11.25,11.25,0,1,1,13.5,2.25,11.25,11.25,0,0,1,24.75,13.5Z" transform="translate(0)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
									<path id="Path_4" data-name="Path 4" d="M17.23,17.225l-4.743-4.737" transform="translate(9.02 9.025)" fill="none" stroke="#8e9298" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
								</g>
							</svg>
						</a>

						<div class="setting-main" id="settings-dropdown">
							<object  v-html="svgs_data.setting_icon" @click="showSettings()"></object>
							<div v-if="navbarStore.settings_dropdown" class="setting-dropdown-main active">
								<setting-dropdown v-if="navbarStore.settings_dropdown"></setting-dropdown>
							</div>
						</div>
					</div>

					<div class="header-btn">
						<a @click="showLoginModal()" class="btn-small">{{ $t('general.login') }}</a>
						<a @click="showRegisterModal()" class="btn-small btn-secondary">{{ $t('general.register') }}</a>
					</div>
				</div>
			</template>

			<div class="right-content rightNav-tablet">
				<TabNav></TabNav>
			</div>
		</div>
	</header>

</template>
<style scoped>
/* mobile menu slide in */
.slide-fade-enter-active {
	transition: all 0.01s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.01s ease-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(-20px);
}
html[dir="rtl"] .slide-fade-enter-from{
	transform: translateX(20px);
}
html[dir="rtl"] .slide-fade-leave-to {
	transform: translateX(20px);
}
html[dir="rtl"] .menu-mobile .nav-list {
	left:unset;
	right:0;
}
.menu-mobile .nav-list {
	box-shadow: 200px 0px 0px rgb(0 0 0 / 42%);
}
html[dir="rtl"] .menu-mobile .nav-list {
	box-shadow: -200px 0px 0px rgb(0 0 0 / 42%);
}
.menu-mobile ul {
	flex: 0 0 255px;
	display: block;
}
.first-menu {
	margin-bottom: 20px;
}
.second-menu {
	margin-bottom: 20px;
}
.third-menu {
	margin-bottom: 20px;
}
.bottom-menu {
	/* position: absolute; */
	/* bottom: 170px; */
	width: 215px;
}
a.basic-menu-font {
	font-weight: 400 !important;
	font-size:16px;
}
@media screen and (min-width: 1600px) and (max-width: 1880px) {
	header.Logged-in .header-left{
		gap:40px;
	}
}
/* @media screen and (min-width: 1440px) and (max-width: 1599px) {
	header.Logged-in .header-left{
		gap:19px!important;
	}
} */
@media screen and (min-width:375px) and (max-width: 382px) {
	header.Logged-in .header-inner .login-info .user-profile-top .user-detail p {
		width: 39px!important;
	}
}

@media screen and (min-width: 320px) and (max-width: 991px) {
	li {
		padding-bottom: unset;
		padding-top: unset;
		line-height: unset;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.menu-mobile h6 {
		font-size: 14px;
		padding-top: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.icon.desktop-only{
		display:none!important;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	header.header-home.Logged-in .header-inner .login-info .header-icon {
		gap: 8px!important
	}
	header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
		top:60px!important;
	}
}


@media screen and (min-width: 320px) and (max-width: 344px) {
	html[dir="rtl"] header .header-left .logo img {
		margin-left: 6px;
	}
}

@media screen and (min-width: 320px) and (max-width: 767px){
	html[dir="rtl"] header.header-home.Logged-in .header-inner .login-info .header-icon {
		gap: 5px!important;
	}
}


@media screen and (min-width: 320px) and (max-width: 340px) {
	html[dir="rtl"] header.header-home {
		padding: 7px 0px !important

	}
}

@media screen and (min-width:1440px) and (max-width: 1599px) {
	header.Logged-in .header-left {
		gap: 19px;
	}
	header.Logged-in .header-inner {
		padding-right:unset;
	}
}

@media screen and (min-width:1360px) and (max-width: 1439px) {
	header.Logged-in .header-left {
		gap: 20px
	}
	header.Logged-in .header-inner .login-info {
		gap: 20px!important;
	}
	header.Logged-in .header-inner {
		padding-right:unset;
	}
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 15px;
	}
}
/* 1200 */
@media screen and (min-width:1261px) and (max-width: 1359px) {
	header.Logged-in .container-lg {
		max-width:1320px;
	}
	header.Logged-in .header-left {
		gap: 20px
	}
	header.Logged-in .header-inner {
		padding-right:unset;
	}
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 15px;
	}
	.email-verification-icon {
		margin-right: -10px;
	}
}
@media screen and (min-width:1164px) and (max-width: 1260px) {
	header.Logged-in .container-lg {
		max-width:1320px;
	}
	header.Logged-in .header-left {
		gap: 9px;
	}
	header.Logged-in .header-inner {
		padding-right:unset;
	}
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 12px;
	}
	.email-verification-icon {
		margin-right: -10px;
	}
	header.Logged-in .logo-img {
		width: 100px;
	}
}
/* @media screen and (min-width:1000px) and (max-width: 1163px) {
	header.Logged-in .container-lg {
		max-width:1320px;
	}
	header.Logged-in .header-left {
		gap: 9px;
	}
	header.Logged-in .header-inner {
		padding-right:unset;
	}
	nav ul {
		gap: 9px;
	}
	.email-verification-icon {
		margin-right: -10px;
	}
	header.Logged-in .logo-img {
		width: 90px;
	}
} */
@media screen and (min-width:1237px) and (max-width: 1280px) {
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 25px;
	}
}
@media screen and (min-width:1200px) and (max-width: 1236px) {
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 17px;
	}
}
@media screen and (min-width:1181px) and (max-width: 1199px) {
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 17px;
	}
	header.Logged-in .header-left {
		gap: 12px;
	}
}
@media screen and (min-width:1154px) and (max-width: 1180px) {
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 9px;
	}
	header.Logged-in .header-left {
		gap: 9px;
	}
	header.Logged-in .logo-img {
		width: 120px;
	}
}
@media screen and (min-width: 992px) and (max-width: 1280px) {
	header.Logged-in .header-left {
		gap: 10px;
	}
	header.Logged-in nav ul li a{
		font-size: 13px;
	}
	.email-verification-icon {
		margin-right: -10px;
	}
}
@media screen and (min-width:1102px) and (max-width: 1153px) {
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap: 13px;
	}
	header.Logged-in .header-inner .login-info {
		gap: 18px!important;
	}
	header.Logged-in .header-inner .login-info .header-icon {
		padding-left:unset;
	}
	header.Logged-in .logo-img {
		width: 110px;
	}
}
@media screen and (min-width:1096px) and (max-width: 1101px) {
	header.Logged-in .header-inner .login-info .header-icon {
		padding-left:unset;
		gap:5px!important;
	}
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap:9px;
	}
	header.Logged-in .logo-img {
		width: 100px;
	}
}
@media screen and (min-width:1057px) and (max-width: 1095px) {
	header.Logged-in .header-inner .login-info .header-icon {
		padding-left:unset;
		gap:5px!important;
	}
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap:9px;
	}
	header .header-left {
		gap: 10px;
	}
	header.Logged-in .logo-img {
		width: 100px;
	}
}
@media screen and (min-width:1031px) and (max-width: 1056px) {
	header.Logged-in .header-inner .login-info .header-icon {
		padding-left:unset;
		gap:5px!important;
	}
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap:8px;
	}
	header .header-left {
		gap: 10px;
	}
	header.Logged-in .logo-img {
		width: 80px;
	}
}
@media screen and (min-width:992px) and (max-width: 1030px) {
	header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap:8px;
	}
	header .header-left {
		gap: 10px;
	}
	header.Logged-in .logo-img {
		width: 80px;
	}
	header.Logged-in .btn-small {
		width: 80px;
		padding: 5px;
	}
	header.Logged-in nav ul li a {
		font-size: 13px;
	}
}
@media screen and (min-width: 992px) and (max-width: 1112px) {
	html[dir="rtl"] header.Logged-in .header-left {
		gap: 11px;
	}
	html[dir="rtl"] header.Logged-in nav ul li a {
		font-size: 13px;
	}
	html[dir="rtl"] header.Logged-in .header-left .menu-desktop ul.nav-list {
		gap:5px;
	}
}

a.custom-font {
	font-family: Arial, sans-serif;
	font-weight: 800 !important;
	font-size: 16px !important;
	color:#e5e5e5 !important;
}
svg.svg-inline--fa.fa-comments.fa-w-18,
svg.svg-inline--fa.fa-download.fa-w-16 {
	margin-left: 10px;
}
html[dir="rtl"] svg.svg-inline--fa.fa-comments.fa-w-18{
	margin-left:unset;
	margin-right:10px;
}
html[dir="rtl"] svg.svg-inline--fa.fa-download.fa-w-16{
	margin-left:unset;
	margin-right:10px;
}
.menu-mobile .nav-list {
	row-gap: 20px;
}
nav ul li {
	display: block;
	line-height: 1.5;
	color: #878a90;
	text-decoration: none;
	transition: .2s ease-in-out;
	font-size: 14px;
	font-weight: 300;
}
nav ul li ul li a {
	padding: 10px;
}
nav ul .dropdown-menu .active:after {
	content: "";
	width: 150px;
	height: 48%;
	position: absolute;
	bottom: 0px;
	left: 0;
	border-bottom: 2px solid #63A246;
}
.menu-desktop .dropdown-menu {
	display: none;
	position: absolute;
	z-index: 1;
	background: #202833;
	width: 150px;
	border-radius: 5px;
	margin-top: 5px;
}
.menu-desktop .dropdown:hover .dropdown-menu,
.menu-desktop .dropdown-menu:hover {
	display: block;
}

header.Logged-in .header-inner .login-info .email-verification-icon .tooltip-main {
	display: none;
}
@media screen and (min-width: 992px) and (max-width: 1069px) {
	header.Logged-in .header-inner .login-info {
		gap: 20px;
	}
	nav ul {
		gap: 15px;
	}
	header .header-left {
		gap: 15px;
	}
	html[dir="rtl"] header.Logged-in .header-inner .login-info {
		gap: 20px;
	}
	html[dir="rtl"] nav ul {
		gap: 15px;
	}
	html[dir="rtl"] header .header-left {
		gap: 25px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
	html[dir="rtl"] header.Logged-in .header-inner .login-info .header-icon {
		padding-right:15px;
		padding-left:unset;
	}
}

@media screen and (min-width: 1070px) and (max-width: 1085px) {
	html[dir="rtl"] nav ul {
		gap:25px
	}
}

header.Logged-in .header-inner .profile-balance {
	display: block;
}

.right-content .header-icons .setting-main .setting-dropdown-main.active::before {
	display: block;
}

header.Logged-in .header-inner .login-info .user-profile-top .user-detail {
	gap: 11px;
}

header.Logged-in .header-inner .login-info .user-profile-top .user-detail p {
	font-size: 12px;
	width: 100px;
	overflow: hidden;
	text-wrap: nowrap;
	text-overflow: ellipsis;
	text-align: end;
}

header.Logged-in .header-inner .login-info .profile-balance h6 {
	font-size: 12px;
	width: 62px;
	overflow: hidden;
	text-wrap: nowrap;
	text-overflow: ellipsis;
}
/* @media screen and (min-width: 320px) and (max-width: 767px) {
	.right-content .header-icons .setting-main .setting-dropdown-main {
		top: 34px;
		padding: 15px;
		right: -20px!important;
	}
} */
@media screen and (min-width: 320px) {
	header.Logged-in .header-inner .login-info .user-profile-top .user-detail p {
		width: 20px;
	}
}

@media screen and (min-width: 375px) and (max-width: 424px) {
	header.Logged-in .header-inner .login-info .user-profile-top .user-detail p {
		width: 50px;
	}
}

@media screen and (min-width: 425px) and (max-width: 767px) {
	header.Logged-in .header-inner .login-info .user-profile-top .user-detail p {
		width: 50px;
	}
}
@media screen and (min-width: 1281px) and (max-width: 3000px) {
	header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
			background: #131C27;
			padding: 20px 20px 19px 20px!important;
			width: 264px;
			position: absolute;
			right: -36px;
			top: 36px;
			display: block;
			border-radius: 0 0 5px 5px;
			box-shadow: 5px 10px 15px rgba(19, 23, 29, 0.25);
	}
}
@media screen and (min-width: 1281px) and (max-width: 1359px) {
	.container-lg {
		max-width: 1225px;
	}
}
@media screen and (min-width: 992px) and (max-width: 1280px) {
	header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
		top: 38px;
		right: -200px;
		padding: 20px 20px 19px 20px;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
		top: 38.5px;
		right: -200px;
		padding: 20px 20px 19px 20px;
	}
}
@media screen and (min-width: 395px) and (max-width: 767px) {
	header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
		padding: 18px 24px 19px 24px;
		width: 100%;
		right: 0;
		top: 52px;
		left: 0;
		z-index: 32;
	}
}
@media screen and (min-width: 330px) and (max-width: 394px) {
	header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
		padding: 18px 24px 19px 24px;
		width: 100%;
		right: 0;
		top: 50px;
		left: 0;
		z-index: 32;
	}
	/* header .header-left .logo img {
		width: 56px;
		padding-top: 3px;
	} */
}

@media screen and (min-width: 320px) and (max-width: 329px) {
	header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
		padding: 18px 24px 19px 24px;
		width: 100%;
		right: 0;
		top: 50px;
		left: 0;
		z-index: 32;
	}
}


@media screen and (min-width: 992px) and (max-width: 1280px){
	.container-lg {
		max-width: 100%;
		width: calc(100% - 20px);
	}
}
@media screen and (min-width: 320px) and (max-width: 344px){
	header.Logged-in .header-inner .login-info .user-profile-top.active .user-detail:before {
		content: "";
		position: absolute;
		background: #131c27;
		width: 55px;
	}
	header .header-left {
		gap: 8px;
		margin-right: 10px;
	}
	html[dir="rtl"] header .header-left {
		gap: 8px;
		margin-right:unset;
		margin-left: 10px;
	}
}
@media screen and (min-width: 768px) {
	header.Logged-in .header-inner .login-info .user-profile-top .user-detail p {
		width: 80px;
	}
}
html[dir="rtl"] header.Logged-in .header-inner .login-info .user-profile-top .user-detail svg {
	right: unset;
	left: -20%;
}

html[dir="rtl"] header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
	right: unset;
	left: -167px
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] header.Logged-in .header-inner .login-info .user-profile-top.active :deep(.user-dropdown-info) {
		right: 0;
		left: 0;
	}
	html[dir="rtl"] header.Logged-in .header-inner .login-info .user-profile-top .user-detail svg {
		right: unset;
		left: unset;
	}
}


.right-content .header-icons .setting-main .setting-dropdown-main {
	background: #202833;
	position: absolute;
	top: 38px;
	width: 260px;
	right: -200px;
	padding: 30px 20px 20px;
	border-radius: 5px;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
	display: block;
	cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] .right-content .header-icons .setting-main .setting-dropdown-main {
		top: 35px!important;
		padding: 15px!important;
		right:unset!important;
		left:-60px!important;
	}
}
html[dir="rtl"] .right-content .header-icons .setting-main .setting-dropdown-main {
	background: #202833;
	position: absolute;
	top: 38px;
	width: 260px;
	left: -200px;
	padding: 30px 20px 20px;
	border-radius: 5px;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
	display: block;
	cursor: pointer;
	right: unset;
}
header.Logged-in .header-inner .login-info .header-icon .setting-main .setting-dropdown-main {
	top: 56px;
}
@media screen and (min-width: 329px) and (max-width: 767px) {
	header.Logged-in .header-inner .login-info .header-icon .setting-main .setting-dropdown-main {
		top: 37px;
	}
}
@media screen and (min-width: 320px) and (max-width: 329px) {
	header.Logged-in .header-inner .login-info .header-icon .setting-main .setting-dropdown-main {
		top: 37px;
		right:-43px;
	}
}
@media screen and (min-width: 395px) and (max-width: 767px) {
	header.Logged-in .header-inner .login-info .header-icon .setting-main .setting-dropdown-main {
		top: 38px;
	}
}

@media screen and (min-width: 768px) and (max-width: 3000px) {
	html[dir="rtl"] header.header-home.Logged-in.dark-header .header-inner .login-info .header-icon {
		border-left: unset !important;
		border-right: 1px solid #4E545D !important;
	}
}
@media screen and (min-width: 1281px) and (max-width: 3000px) {
	html[dir="rtl"] header.Logged-in .header-inner .login-info .header-icon .setting-main {
		padding-right: 10px
	}
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
	html[dir="rtl"] header.header-home.Logged-in .header-inner .login-info .header-icon .login-search {
		display: block;
		padding-right: 10px;
	}

	header.Logged-in .header-inner .login-info .header-icon {
		flex-direction: unset !important;
	}
}
@media screen and (min-width: 375px) and (max-width: 394px) {
	.header.header-home.Logged-in .container-lg {
		padding-left:0px!important;
		padding-right:0px!important;
		padding:0x!important;
	}
	header.header_without_login.header-home{
		padding:0x!important;
	}
}
@media screen and (min-width: 768px) and (max-width: 991px) {
	header.Logged-in .header-inner .login-info .header-icon .setting-main .setting-dropdown-main {
		top: 58px;
	}




}
@media screen and (min-width: 330px) and (max-width: 767px) {
	header.Logged-in .header-inner .login-info .header-icon .setting-main .setting-dropdown-main {
		right: -50px;
	}

}


@media screen and (min-width: 320px) and (max-width: 767px) {
	header.header-home.Logged-in.dark-header {
		padding: 10px;
	}
}
@media screen and (min-width: 768px) and (max-width: 3000px) {
	header.Logged-in .header-inner .login-info .header-icon .setting-main .setting-dropdown-main {
		right:0px;
	}
}


@media screen and (min-width: 339px) and (max-width: 394px){
	header.Logged-in .header-inner .login-info .user-profile-top.active .user-dropdown-info {
		top: 52px;
	}

}

@media screen and (min-width: 320px) and (max-width: 394px){

	header.Logged-in .header-inner .login-info .user-profile-top .user-detail {
		gap: 4px !important;
	}
}

@media screen and (min-width: 320px) and (max-width: 330px){


	header.Logged-in .header-inner .login-info{
		gap: 0px !important;

	}

}

@media screen and (max-width: 1025px) {
	.nav-list{
		gap: 10!important;
	}
}



.notification-num {
	position: absolute;
	top: 13px;
	right: -5px;
	background: #D93444;
	color: #fff;
	font-size: 10px;
	padding: 0px 0px;
	border-radius: 2px;
	line-height: 1;
	width: 13px;
	height: 13px;
	text-align: center;
}

html[dir="rtl"] .notification-num {
	right: unset;
	left: -5px;
}

/* SHARE CODE */
.disabled {
	pointer-events: none;
}

.text-field {
	position: relative;
}

.clear-button {
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translate(-50%, -50%);
	cursor: pointer;
	width: 14px;
	color: #8E9298;
}

html[dir="rtl"] .clear-button {
	left: 16px;
	right: unset;
}

.share-btn {
	background: #FFBB02;
	color: #202833;
	border: 1px solid transparent;
	height: 30px;
	transition: 0.3s ease-in-out;
	border-radius: 4px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 500;
	text-decoration: none;
	display: inline-block;
	text-align: center;
	transition: 0.3s ease-in-out;
	padding: 0px 5px 3px 5px;
	width: 100%;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: 10px;
}

.share-btn:hover {
	border: 1px solid #878A90;
	background: #131C27;
	color: #fff;
}

.btn-disabled {
	border: 1px solid #878A90;
	background: #131C27;
	color: #fff;
	pointer-events: none;
}

.form-input {
	width: 100%;
	padding: 5px 40px 5px 15px;
	background-color: #363D47;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 100;
	color: #8E9298;
}

html[dir="rtl"] .form-input {
	padding: 5px 15px 5px 40px;
}

.header-mobile-icon {
	width: 24px;
	height: 23px;
	margin-right: 5px;
}

.icon {
	display: flex !important;
	align-items: flex-end !important;
}

.icon a:after {
	content: unset!important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
	.notification-num {
		position: absolute;
		top: 13px;
		right: 0px;
		font-size: 9px;
		width: 10px;
		height: 10px;
	}

	html[dir="rtl"] .notification-num {
		right: unset;
		left: 0px;
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	html[dir="rtl"] .notification-num {
		right: unset;
	}
}

@media screen and (min-width: 992px) and (max-width: 1055px) {
	.notification-num {
		right: 0px;
		font-size: 9px;
		width: 10px;
		height: 10px;
	}

	html[dir="rtl"] .notification-num {
		right: unset;
		left: 0px;
	}
}

@media screen and (min-width: 1056px) and (max-width: 1280px) {
	html[dir="rtl"] .notification-num {
		right: unset;
	}
}
</style>
import { defineStore } from 'pinia';
import { ref } from 'vue'
import _ from 'lodash';

export const useWithdrawalsStore = defineStore('withdrawals', {
	state: () => ({
		payment_gateways: ref({}),
	}),
	getters: {
		getPaymentGateways(state) {
			const allowed_payment_gateways = ['plisio', 'coinpayments', 'hubtel', '0xprocessing', 'iran-voucher', 'central-pay', 'perfect-money'];

			return _.filter(state.payment_gateways, (gateway) => {
				return _.includes(allowed_payment_gateways, gateway.slug);
			});
		},
		getAvailableBonuses(state) {
			return state.available_bonuses;
		},
	},
	actions: {
		fetchPaymentGateways() {
			return global.axios.get(process.env.VUE_APP_API_URL + '/account/bank/withdraw/payment_gateways').then((response) => {
				this.payment_gateways = response.data.data;

				return Promise.resolve(response.data.data);
			}).catch((error) => {
				return Promise.reject(error);
			})
		},
		getPaymentGateway(slug) {
			return _.find(this.payment_gateways, { 'slug': slug });
		},
		// Plisio
		createWithdrawPlisio(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/withdraw/plisio', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// CoinPayments
		createWithdrawCoinPayments(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/withdraw/coinpayments', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// 0xprocessing
		createWithdraw0xProcessing(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/withdraw/0xprocessing', payload).then((response) => {
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Hubtel
		createWithdrawHubtel(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/withdraw/hubtel', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Iran Voucher
		createWithdrawIranVoucher(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/withdraw/iran-voucher', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Central Pay
		createWithdrawCentralPay(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/withdraw/central-pay', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		// Perfect Money
		createWithdrawPerfectMoney(payload) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/account/bank/withdraw/V2/perfect-money', payload).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		setPaymentGateways(data) {
			this.payment_gateways = data;
		}
	},
})
<script setup>
/* eslint-disable */
import { computed, watch, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { openModal } from '@kolirt/vue-modal';
import { BetSlip } from '@/stores/BetSlip';
import { useLanguagesStore } from '@/stores/languages';
import { useCurrenciesStore } from '@/stores/currencies';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useCustomerStore } from '@/stores/customer';
import { useLiveSportsStore } from '@/stores/live_sports';
import * as Combinatorics from 'js-combinatorics';
import { useOddsStore } from '@/stores/odds';
import { useSharedBetslipsStore } from '@/stores/shared_betslips';
import { useI18n } from 'vue-i18n';

import Login from '@/components/modals/Login.vue';
import Register from '@/components/modals/Register.vue';
import ShareBet from '@/components/modals/ShareBetModal.vue';

import commonSvgs from '@/assets/svgs/commonSvgs.json'

const svg_data = ref(commonSvgs);

const oddsStore = useOddsStore();
const router = useRouter();
const betslipStore = BetSlip();
const languagesStore = useLanguagesStore();
const currenciesStore = useCurrenciesStore();
const preSportsStore = usePreSportsStore();
const customerStore = useCustomerStore();
const liveSportsStore = useLiveSportsStore();
const sharedBetslipStore = useSharedBetslipsStore();
const { t } = useI18n();
const share_url = ref(null);
const loading = ref(false);
const share_id = ref(null);
const share_url_text = ref(t('general.copy_url'));
const share_id_text = ref(t('general.copy_code'));
const input_type = ref(null);
const is_focused = ref(false);


const showShareModal = () => {
	let data = [];
	loading.value = true;

	for (const index in betslipStore.betslip.bets) {
		const bet = betslipStore.betslip.bets[index];

		data.push({
			bet_uuid: bet.odd_info.uuid,
			fixture_id: bet.odd_info.fixture_id
		});
	};

	sharedBetslipStore.storeSharedBetslip(JSON.stringify(data)).then((response) => {
		loading.value = false;
		share_url.value = response.message;
		share_id.value = response.code;

		openModal(ShareBet , { share_url: share_url.value, share_id: share_id.value })
		.then((data) => {
			// Commenting out or removing the condition to show the register modal
			// if (data.show_register) showRegisterModal();
		})
		.catch(() => {});
		
	}).catch((error) => {
		loading.value = false;
	})

};

const focusStakeInput = (system) => {
	is_focused.value = true;
	input_type.value = system;
	if (betslipStore.betslip[system] == 0) betslipStore.betslip[system] = '';
}

const focusOutStakeInput = (system) => {
	// is_focused.value = false;
	if (betslipStore.betslip[system] == '') betslipStore.betslip[system] = 0;
}

const removeFromBetslip = (bet) => {
	betslipStore.removeFromBetslip({ odd_uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id })
}

const acceptChanges = () => {
	betslipStore.removeBetslipErrors();
}

const removeAllBets = () => {
	betslipStore.resetBetslip();
	betslipStore.toggleBetslip();
}

const validateStake = (event) => {
	if (event.key === ',') {
		event.preventDefault();
		if (!event.target.value.includes('.')) event.target.value += '.';
	}

	if (
		(event.key < '0' || event.key > '9') &&
		event.key !== '.' &&
		event.key !== 'Backspace'
	) {
		event.preventDefault();
	}
}

const toggleFreebet = (freebet) => {
	betslipStore.toggleFreebetOnMultiple(freebet);
}

const goToFixture = (bet) => {
	if (bet.odd_info.live) {
		router.push({ name: 'live-sports-eventview', params: { fixture_id: bet.odd_info.fixture_id } });
		return;
	}

	router.push({ name: 'pre-sports-fixturebook', params: { sport_id: bet.odd_info.sport_id, region_id: bet.odd_info.region_id, competition_id: bet.odd_info.competition_id, fixture_id: bet.odd_info.fixture_id } });
}

const calculateMultiplePrice = computed(() => {
	let price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			price = price * bet.odd_info.price
		}
	}

	return price.toFixed(2);
});

const calculateMultipleTax = computed(() => {
	if (isNaN(betslipStore.betslip.system_multiple_stake)) return 0;
	let multiple_stake = Number(betslipStore.betslip.system_multiple_stake);

	let multiple_price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			multiple_price = multiple_price * bet.odd_info.price
		}
	}

	let multiple_return = 0;
	if (betslipStore.betslip.multiple_freebet_id) {
		multiple_return = (multiple_price * multiple_stake) - multiple_stake;
		return (multiple_return * betslipStore.betslip_tax);
	} else {
		multiple_return = multiple_price * Number(multiple_stake);
		return (multiple_return - multiple_stake) * betslipStore.betslip_tax;
	}
});

const calculateMultipleReturn = computed(() => {
	if (isNaN(betslipStore.betslip.system_multiple_stake)) return 0;
	let multiple_stake = Number(betslipStore.betslip.system_multiple_stake);

	let multiple_price = 1;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			multiple_price = multiple_price * bet.odd_info.price
		}
	}

	let tax_amount = 0;
	let multiple_return = 0;
	if (betslipStore.betslip.multiple_freebet_id) {
		multiple_return = (multiple_price * multiple_stake) - multiple_stake;
		if (betslipStore.betslip_tax) tax_amount = multiple_return * betslipStore.betslip_tax;
	} else {
		multiple_return = multiple_price * multiple_stake;
		if (betslipStore.betslip_tax) tax_amount = (multiple_return - multiple_stake) * betslipStore.betslip_tax;
	}

	return multiple_return - tax_amount;
});

const calculateSinglesReturnTax = computed(() => {

	let tax_amount = 0;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			let bet_return = bet.odd_info.price * betslipStore.betslip.system_singles_stake;
			tax_amount += bet_return * betslipStore.betslip_tax;;
		}
	}

	return tax_amount;
});

const calculateSinglesReturn = computed(() => {

	let possible_return = 0;
	for (const bet_uuid in betslipStore.betslip.bets) {
		if (Object.hasOwnProperty.call(betslipStore.betslip.bets, bet_uuid)) {
			const bet = betslipStore.betslip.bets[bet_uuid];
			let bet_return = bet.odd_info.price * betslipStore.betslip.system_singles_stake;
			let tax_amount = bet_return * betslipStore.betslip_tax;

			possible_return += bet_return - tax_amount;
		}
	}

	return possible_return;
});

const calculateTotalSystemBets = computed(() => {
	if (betslipStore.countBetslipBets > 14) return 13;
	else return betslipStore.countBetslipBets - 2;
})

const calculateSystemBets = (system_size) => {
	let bets = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), system_size);

	return bets.length
}

const calculateSystemReturnTax = (system_size) => {
	if (isNaN(betslipStore.betslip['systemX' + system_size + '_stake'])) return 0;

	let combinations = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), system_size);

	let total_stakes = 0;
	let return_possible = 0;
	[...combinations].forEach(combination => {
		let system_price = 1;
		combination.forEach(bet => {
			system_price = system_price * bet.odd_info.price;
		})

		total_stakes += Number(betslipStore.betslip['systemX' + system_size + '_stake']);
		return_possible += Number(betslipStore.betslip['systemX' + system_size + '_stake']) * system_price;
	});

	return (return_possible - total_stakes) * betslipStore.betslip_tax;
}

const calculateSystemReturn = (system_size) => {
	if (isNaN(betslipStore.betslip['systemX' + system_size + '_stake'])) return 0;

	let combinations = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), system_size);

	let total_stakes = 0;
	let return_possible = 0;
	[...combinations].forEach(combination => {
		let system_price = 1;
		combination.forEach(bet => {
			system_price = system_price * bet.odd_info.price;

		})

		total_stakes += Number(betslipStore.betslip['systemX' + system_size + '_stake']);
		return_possible += Number(betslipStore.betslip['systemX' + system_size + '_stake']) * system_price;
	});

	let tax_amount = 0;
	if (betslipStore.betslip_tax) tax_amount = (return_possible - total_stakes) * betslipStore.betslip_tax;

	return return_possible - tax_amount;
}

const showLoginModal = () => {
	openModal(Login).then((data) => {
		if (data.show_register) showRegisterModal();
	}).catch(() =>{});
}

const showRegisterModal = () => {
	openModal(Register).then((data) => {
		if (data.show_login) showLoginModal();
	}).catch(() =>{});
};

const placeBetslip = () => {
	if (!customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	if (betslipStore.processing_placement) return;

	betslipStore.placeBetslip();
}

const calculateSystemStakes = computed(() => {
	let system_stakes = 0;

	if (Object.keys(betslipStore.betslip.bets).length > 2) {
		for (let index = 2; index <= Object.keys(betslipStore.betslip.bets).length; index++) {
			let  system_stake = betslipStore.betslip['systemX' + index + '_stake'];
			let combinations = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), index);
			system_stake = Number(system_stake) * Number(combinations.length);

			if (!isNaN(system_stake)) system_stakes += Number(system_stake);
		}
	}

	let system_single_stakes = Object.keys(betslipStore.betslip.bets).length * Number(betslipStore.betslip.system_singles_stake);
	return system_stakes + system_single_stakes + Number(betslipStore.betslip.system_multiple_stake);
})

onMounted(() => {
	betslipStore.validateSameFixtures();
})

watch(() => betslipStore.countBetslipBets, (newCount, oldCount) => {
	betslipStore.validateSameFixtures();
})

const updateBetPrice = (type, value) => {
	if (input_type.value == '') {
		alert("Please click on input");
	}else{
		betslipStore.betslip[input_type.value] = betslipStore.validateInput(betslipStore.betslip[input_type.value], type, value)
	}
}

const getMaxBet = () => {
	let system_number = null;
	const match = input_type.value.match(/systemX(\d+)_stake/);
	if (match) {
		system_number = match[1];
	}

	if (!customerStore.isLoggedIn) {
		showLoginModal();
		return;
	}

	let bet_uuids = [];
	for (const key in betslipStore.betslip.bets) {
		const bet = betslipStore.betslip.bets[key];
		bet_uuids.push({uuid: bet.odd_info.uuid, fixture_id: bet.odd_info.fixture_id});
	}

	let payload = {};
	payload.bets = bet_uuids
	payload.type = 'multiple';

	betslipStore.calculateMaxBet(payload).then(response => {
		if (system_number == null && input_type.value == 'system_singles_stake') {
			betslipStore.betslip.system_singles_stake = (response.max_bet / Object.keys(betslipStore.betslip.bets).length).toFixed(2);
		} else if (system_number == null && input_type.value == 'system_multiple_stake') {
			betslipStore.betslip.system_multiple_stake = response.max_bet.toFixed(2);
		} else if (system_number != null) {
			let bets = Combinatorics.Combination.of(Object.values(betslipStore.betslip.bets), system_number);
			betslipStore.betslip['systemX' + system_number + '_stake'] = (response.max_bet / Number(bets.length)).toFixed(2);
		}
	}).catch(error => {
		console.log(error);
	});
}


const shareBetslip = () => {
	let data = [];
	loading.value = true;

	for (const index in betslipStore.betslip.bets) {
		const bet = betslipStore.betslip.bets[index];

		data.push({
			bet_uuid: bet.odd_info.uuid,
			fixture_id: bet.odd_info.fixture_id
		});
	};

	sharedBetslipStore.storeSharedBetslip(JSON.stringify(data)).then((response) => {
		loading.value = false;
		share_url.value = response.message;
		share_id.value = response.code;
	}).catch((error) => {
		loading.value = false;
	})
}

// const copyToClipboard = () => {
// 	navigator.clipboard.writeText(share_url.value);
// 	share_url_text.value = t('general.copied')

// 	setTimeout(() => {
// 		share_url_text.value = t('general.copy_url');
// 	}, 2000);
// }

// const copyIdToClipboard = () => {
// 	navigator.clipboard.writeText(share_id.value);
// 	share_id_text.value = t('general.copied')

// 	setTimeout(() => {
// 		share_id_text.value = t('general.copy_code');
// 	}, 2000);
// }

</script>
<template>
	<div class="betslip-top-area">
		<div class="betslip-beting-area">
			<div class="inner-tab-content">
				<div v-for="bet in betslipStore.betslip.bets" :key="bet.odd_info.uuid" class="bets_body">
					<div class="over_row" :style="bet.has_error ? 'display: grid; grid-template-columns: auto 1fr auto;' : '' " :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
						<div class="circle" v-if="bet.has_error">
							<img v-if="bet.has_error" src="/assets/images/pink_circle_img.png" alt="icon">
						</div>
						<div class="left">
							<div class="top_content"  :style="!bet.odd_info.live ? '' : ''">
	
							<div class="live-badge" v-if="bet.odd_info.live" v-html="svg_data.live_badge"></div>
	
							<div class="over">
								<p>{{ bet.odd_info.odd_name_translations[languagesStore.selectedLanguage.locale] }}</p>
							</div>
							</div>
							<div class="subtitle">
								<p>{{ bet.odd_info.market_name_translations[languagesStore.selectedLanguage.locale] }}</p>
							</div>
						</div>
						<div class="right">
							<div class="value" :class="{'betup': bet.odd_info.price > bet.price, 'betdown': bet.odd_info.price < bet.price}">
								<p>{{ bet.odd_info.sp ? bet.odd_info.placeholder : oddsStore.oddsFormat(bet.odd_info.price) }}</p>
							</div>
							<span class="seprater"></span>
							<div class="clear-ico betslip-button" @click="removeFromBetslip(bet)">
								<svg xmlns="http://www.w3.org/2000/svg" width="12" height="14.741" viewBox="0 0 12 14.741">
									<g id="Group_1850" data-name="Group 1850" transform="translate(0.5 0.5)">
										<g id="delete" transform="translate(0)">
										<path id="Path_896" data-name="Path 896" d="M176,21.08A1.08,1.08,0,0,1,177.08,20h2.161a1.08,1.08,0,0,1,1.08,1.08" transform="translate(-172.507 -20)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
										<line id="Line_157" data-name="Line 157" x2="11" transform="translate(0 3.143)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
										<g id="Path_897" data-name="Path 897" transform="translate(-94.69 -135.265)" fill="none" stroke-linecap="round" stroke-miterlimit="10">
											<path d="M96,140l1.079,8.56a1.074,1.074,0,0,0,1.063.946h4.283a1.074,1.074,0,0,0,1.063-.946l1.079-8.56Z" stroke="none"/>
											<path d="M 97.13394165039062 141.0000152587891 L 98.07138824462891 148.4382934570312 C 98.07550048828125 148.4717102050781 98.10279846191406 148.5062866210938 98.14138793945312 148.5062866210938 L 102.4241638183594 148.5062866210938 C 102.4627304077148 148.5062866210938 102.4900283813477 148.4717102050781 102.4945220947266 148.4352569580078 L 103.4315795898438 141.0000152587891 L 97.13394165039062 141.0000152587891 M 96 140.0000152587891 L 104.5655212402344 140.0000152587891 L 103.4866714477539 148.560302734375 C 103.4211196899414 149.0935516357422 102.9704132080078 149.5062866210938 102.4241638183594 149.5062866210938 L 98.14138793945312 149.5062866210938 C 97.59510803222656 149.5062866210938 97.1444091796875 149.0935516357422 97.078857421875 148.560302734375 L 96 140.0000152587891 Z" stroke="none" fill="#dbe1e6"/>
										</g>
										<g id="Group_1823" data-name="Group 1823" transform="translate(4.804 7.854)">
											<line id="Line_158" data-name="Line 158" y1="3.165" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
											<line id="Line_159" data-name="Line 159" y1="3.165" transform="translate(1.571)" fill="none" stroke="#dbe1e6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
										</g>
										</g>
									</g>
								</svg>
							</div>
						</div>
					</div>
					<div class="bottom_row">
						<!-- <div class="left" :class="{'suspended': betslipStore.processing_placement }" style="cursor:pointer;" @click="removeFromBetslip(bet)">
							<div class="img" v-html="svg_data.close"> </div>
						</div> -->
						<div class="right">
							<div class="full_detail" :class="{'suspended': bet.odd_info == null || bet.odd_info.status == 'suspended' }">
								<div class="icon">
									<img :src="preSportsStore.preSportWhiteIcon(bet.odd_info.sport_id)"  style="width:14px; height:14px;" alt="card-image">
								</div>
								<div class="value" @click="goToFixture(bet)">
									<div class="title_top">
										<p>{{ bet.odd_info.region_name_translations[languagesStore.selectedLanguage.locale] }} - {{ bet.odd_info.competition_name_translations[languagesStore.selectedLanguage.locale] }}</p>
									</div>
									<div v-if="liveSportsStore.hasOneParticipant(bet.odd_info.sport_id) || bet.odd_info.fixture_outright" class="title_bottom">
										<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }}</p>
									</div>
									<div v-else class="title_bottom">
										<p>{{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][0] }} V {{ bet.odd_info.fixture_participants_translations[languagesStore.selectedLanguage.locale][1] }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<div class="target-all system-tab-inputs">
					<!-- Singles -->
					<div class="row">
						<div class="title ">
							<p>{{ $t('betslip.singles') }} ({{ betslipStore.countBetslipBets }})</p>
							<!-- <p v-if="betslipStore.betslip_tax > 0" style="font-size: 11px; color:#848484;">{{ $t('betslip.tax_amount') }}: {{ currenciesStore.format_currency(calculateSinglesReturnTax) }} </p> -->
							<!-- <p style="font-size: 11px; color:#848484;">{{ $t('betslip.to_return') }}: {{ currenciesStore.format_currency(calculateSinglesReturn) }}</p> -->
						</div>
	
						<div class="right_content_85 stake-input ">
							<span class="euro-icon">{{ currenciesStore.getCurrencySymbol }}</span>
							<input type="text" @keypress="validateStake" v-model="betslipStore.betslip.system_singles_stake" @focus="focusStakeInput('system_singles_stake')" @focusout="focusOutStakeInput('system_singles_stake')" inputmode="none" autocapitalize="off" autocomplete="off" autocorrect="off" min="0" class="pay_btn"/>
						</div>
					</div>
					<!-- Singles End -->
	
					<!-- System Bets -->
					<template v-if="betslipStore.countBetslipBets > 2">
						<div v-for="(row, index) in (betslipStore.countBetslipBets - 2)" :key="index" class="row">
							<div class="title">
								<p>{{ $t('betslip.stake_system') }} X{{ index + 2 }} ({{ calculateSystemBets(index + 2) }})</p>
								<template v-if="betslipStore.countBetslipBets < 15 && !betslipStore.hasSPOdd">
									<p v-if="betslipStore.betslip_tax > 0" style="font-size: 11px; color:#848484;">Tax Amount: {{ currenciesStore.format_currency(calculateSystemReturnTax(index + 2)) }} </p>
									<p style="font-size: 11px; color:#848484;"> {{$t('betslip.to_return')}} : {{ currenciesStore.format_currency(calculateSystemReturn(index + 2)) }}</p>
								</template>
							</div>
	
							<div class="right_content_85 stake-input">
								<span class="euro-icon">{{ currenciesStore.getCurrencySymbol }}</span>
								<input type="text" @keypress="validateStake" v-model="betslipStore.betslip['systemX' + (index + 2) + '_stake']" @focus="focusStakeInput('systemX' + (index + 2) + '_stake')" @focusout="focusOutStakeInput('systemX' + (index + 2) + '_stake')" inputmode="none" autocapitalize="off" autocomplete="off" autocorrect="off" min="0" class="pay_btn"/>
							</div>
						</div>
					</template>
					<!-- End System Bets -->
	
					<!-- Multiple -->
					<div class="row">
						<div class="title">
							<p v-if="betslipStore.countBetslipBets == 2">{{ $t('betslip.doubles') }} ({{ betslipStore.countBetslipBets }})</p>
							<p v-if="betslipStore.countBetslipBets == 3">{{ $t('betslip.trebles') }} ({{ betslipStore.countBetslipBets }})</p>
							<p v-if="betslipStore.countBetslipBets > 3">{{ betslipStore.countBetslipBets }} {{ $t('betslip.folds') }}</p>
						</div>
						<div class="right_content_85 stake-input multiple-bets">
							<div class="flex-container">
								<p v-if="!betslipStore.hasSPOdd" class="betslip-price">@ {{ calculateMultiplePrice }}</p>
								<div class="odd-input">
									<span class="euro-icon">{{ currenciesStore.getCurrencySymbol }}</span>
									<input type="text" @keypress="validateStake" v-model="betslipStore.betslip.system_multiple_stake" @focus="focusStakeInput('system_multiple_stake')" @focusout="focusOutStakeInput('system_multiple_stake')" inputmode="none" autocapitalize="off" autocomplete="off" autocorrect="off" min="0" class="pay_btn"/>
								</div>
	
							</div>
						</div>
					</div>
					<!-- Multiple End -->
				</div>
				<!-- amount select start-->
				<div class="amount-select-main"  :class={system_bet_numpad:is_focused} >
						<div class="amount-select-outer">
							<div class="amount-select-topbar">
								<div class="amount-selet-item">
									<p @click="updateBetPrice('add', 5)">+5</p>
								</div>
								<div class="amount-selet-item">
									<p @click="updateBetPrice('add', 10)">+10</p>
								</div>
								<div class="amount-selet-item">
									<p @click="updateBetPrice('add', 20)">+20</p>
								</div>
								<div class="amount-selet-item">
									<p @click="getMaxBet()">Max</p>
								</div>
							</div>
							<div class="amount-select-bottom">
								<div class="amount-item" @click="updateBetPrice('append', 1)">
									<p>1</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 2)">
									<p>2</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 3)">
									<p>3</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 4)">
									<p>4</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 5)">
									<p>5</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 6)">
									<p>6</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 7)">
									<p>7</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 8)">
									<p>8</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 9)">
									<p>9</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', '.')">
									<p>.</p>
								</div>
								<div class="amount-item" @click="updateBetPrice('append', 0)">
									<p>0</p>
								</div>
								<div class="amount-item"  @click="updateBetPrice('delete', 0)">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14">
										<g id="Group_1851" data-name="Group 1851" transform="translate(-160 -502)">
											<path id="Path_898" data-name="Path 898" d="M0,0H15V14H0L-5,7Z" transform="translate(165 502)" fill="#202833"/>
											<path id="Path_899" data-name="Path 899" d="M.5-3.354,2.464-6.071H1.45L.007-4.03-1.45-6.071H-2.464l1.976,2.73L-2.906,0h.988L.007-2.652,1.9,0h1Z" transform="translate(171.905 512.071)" fill="#fff"/>
										</g>
									</svg>
	
								</div>
							</div>
						</div>
			</div>
			<!-- amount select end -->
		</div>
	</div>

	<div v-if="betslipStore.countBetslipBets > 1" class="bets_body_bottom">
		<div class="system-bets">
		</div>

		<div class="row_1">
			<template v-if="betslipStore.countBetslipBets == 50">
				<div class="error_wrap">
					<div class="detail">
						<p>{{ $t('betslip.bet_limit_exceeded') }}</p>
					</div>
				</div>
			</template>
			<template v-if="betslipStore.same_fixture_error_message">
				<div class="error_wrap">
					<div class="detail">
						<p>{{$t('betslip.bets_can_not_combined')}}</p>
					</div>
				</div>
			</template>
			<template v-else>
				<template v-if="betslipStore.betslip.error_message">
					<div class="error_wrap">
						<div class="detail">
							<p>{{ betslipStore.betslip.error_message }}</p>
						</div>
					</div>
					<div class="bottom-multi-btn">
						<div class="share-grid">
							<!-- <div class="share-inner-grid">
								<button v-if="share_url" class="share-btn" type="button" @click="copyToClipboard()">
									<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_url_text }}
								</button>
								<button v-if="share_id" class="share-btn" type="button" @click="copyIdToClipboard()">
									<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_id_text }}
								</button>
							</div> -->
								<button class="share-btn btn-small" type="button" @click="showShareModal()" v-if="!loading">
									<span v-html="commonSvgs.share_ico"></span>&nbsp;{{ $t('general.share') }}
								</button>
								<div v-else class="loader-main"></div>
						</div>
						<a @click="acceptChanges" href="#" class="btn-small btn-secondary betslip-button">{{$t('betslip.accect_changes')}}</a>
					</div>
					
				</template>
				<template v-else>
					<div class="bottom-multi-btn">
						<div class="share-grid">
							<!-- <div class="share-inner-grid">
								<button v-if="share_url" class="share-btn" type="button" @click="copyToClipboard()">
									<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_url_text }}
								</button>
								<button v-if="share_id" class="share-btn" type="button" @click="copyIdToClipboard()">
									<i class="fa fa-clone" aria-hidden="true"></i>&nbsp;{{ share_id_text }}
								</button>
							</div> -->
								<button class="share-btn btn-small" type="button" @click="showShareModal()" v-if="!loading">
									<span v-html="commonSvgs.share_ico"></span>&nbsp;{{ $t('general.share') }}
								</button>
								<div v-else class="loader-main"></div>
						</div>
						<a @click="placeBetslip" href="#" class="btn-small btn-secondary betslip-button">
						{{ $t('betslip.place_bet') }} {{ currenciesStore.format_currency(calculateSystemStakes) }}
						<div v-if="betslipStore.processing_placement" class="lds-ring"><div></div><div></div><div></div><div></div></div>
						</a>
					</div>
					
				</template>
			</template>
		</div>
	</div>
</template>



<style scoped>
.eventime_mobal_content .bets_body .over_row {
	grid-auto-columns: 9% 60% 29%;
	grid-gap: 1%;
	grid-auto-flow: column;
	display: grid;
	justify-content: start;
	align-items: center;
	text-align: center;
	padding-left: 0;
}

.suspended {
	opacity: 0.2;
	pointer-events: none;
	cursor: not-allowed;
}

.bets {
	max-height: 500px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.bets::-webkit-scrollbar {
	width: 3px;
}

.bets::-webkit-scrollbar-thumb {
	background-color: #8f54a0;
	border-radius: 2px;
	border: 3px solid linear-gradient(#e382bc 0%, #8d54eb 100%);
}

.system-bets {
	max-height: 210px;
	overflow-y: scroll;
}

.system-bets::-webkit-scrollbar {
	width: 3px;
}

.system-bets::-webkit-scrollbar-thumb {
	background-color: #8f54a0;
	border-radius: 2px;
	border: 3px solid linear-gradient(#e382bc 0%, #8d54eb 100%);
}

@media screen and (min-width: 320px) and (max-width: 767px){
	.bets {
		max-height: calc(100vh - 434px)
	}
	.system-bets {
		max-height: 125px;
		overflow-y: scroll;
	}
}


.error_wrap {
	position: relative;
	padding: 5px;
	border: 1px solid red;
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 5px;
}
.error_wrap .detail {
	text-align: center;
	color: red;
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
}

.register-input-check {
	padding: 4px 0px 0;
	display: flex;
	align-items: flex-start;
	gap: 8px;
}
.register-input-check input[type=checkbox] {
	appearance: checkbox !important;
	margin-top: 4px;
}
.register-input-check label {
	color: #878A90;
	font-weight: 300;
	font-size: 12px;
}
.register-input-check label a {
	color: #F2F2F7;
	text-decoration: none;
	font-weight: 300;
}
.register-input-check p {
	width: 95%;
}
.register-input-check .check-dash {
	position: absolute;
	opacity: 0;
}
.register-input-check .check-dash + label {
	position: relative;
	padding: 0;
	display: flex;
	flex-direction: row;
	gap: 5px;
}
.register-input-check .check-dash + label:before {
	content: "";
	margin-right: 0px;
	display: inline-block;
	vertical-align: text-top;
	width: 17.19px;
	height: 16px;
	background: transparent;
	outline: 1px solid #878A90;
	border-radius: 3px;
	margin-top: 0px;
	cursor: pointer;
}
.register-input-check .check-dash:hover + label:before {
	background: transparent;
}
.register-input-check .check-dash:focus + label:before {
	box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.register-input-check .check-dash:checked + label:before {
	background: transparent;
}
.register-input-check .check-dash:checked + label:after {
	content: "";
	position: absolute;
	left: 3px;
	top: 8px;
	width: 2px;
	height: 2px;
	box-shadow: 2px 0 0 #8D54EB, 4px 0 0 #8D54EB, 4px -2px 0 #8D54EB, 4px -4px 0 #8D54EB, 4px -6px 0 #8D54EB, 4px -8px 0 #8D54EB;
	transform: rotate(45deg);
}
.lds-ring {
	display: inline-block;
	position: relative;
	width: 15px;
	height: 15px;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 2px;
	margin-right: 10px;
	border: 2px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

html[dir="rtl"] .eventime_mobal_content .inner-tab-content .bets_body .over_row .right .value {
	text-align: left;
}

.disabled-button {
	background: transparent;
	pointer-events: none;
	border: 1px solid #4E545D;
	color: #4E545D;
}
.loader-main {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: relative;
}
</style>